import axios from 'axios';
import '../Sys/config.js';

var apiUrl = global.platformURI;

export class CalendarServices {
    constructor() {
        axios.interceptors.request.use(
            function (config) {
                let token = localStorage.getItem('ssoToken');
                config.headers.Authorization = `Token ${token}`;
                config.baseURL = apiUrl;
                return config;
            },
            error => {
                console.log('error.response.status', error);
                return error;
            }
        );
    }

    addEvent(payload) {
        const data = JSON.stringify(payload);
        let token = localStorage.getItem('ssoToken');
        const config = {
            method: 'post',
            url: apiUrl + '/main/event',
            headers: { 
                'Authorization': token, 
                'Content-Type': 'application/json'
            },
            data : data
        };
        return axios(config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (err) {
            console.log(err);
            return (err.response && err.response.data) ? err.response.data : err;
        });
    }

    getEvent(id) {
        return axios
            .get(`main/event/${id}`)
            .then(res => res.data);
    }

    getAllEvents(query) {
        return axios.get('main/events/' + query).then(res => res.data);
    }
}
