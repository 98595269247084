import * as React from 'react';
import Layout from "../../Components/layouts";
import { useParams } from "react-router-dom";
import { ReportServices } from '../../Services/ReportServices';
import CustomTabs from '../../Components/CustomTabs';
import { analyticsColumns, overViewColumn, progressColumn, USER_ROLES } from '../../utils/constants'
import DetailsAnalyticsTab from './component/DetailsAnalyticsTab';
import { useAuthDispatch, useAuthState } from '../../Context';
import commonStyles from 'theme/commonStyles';
import { useJsonToCsv } from 'react-json-csv';
import { notify } from 'utils';
import "jspdf-autotable";
import jsPDF from 'jspdf';

const reportServices = new ReportServices();

const Report = () => {

    const commonClasses = commonStyles();
    const { type } = useParams();
    const dispatch = useAuthDispatch();
    const { userDetails } = useAuthState(dispatch);
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [analyticsFilterState, setAnalyticsFilterState] = React.useState({
        certificate_status: [],
        course: [],
        customer: [],
        pageno: 0,
        perpage: 10,
        project: [],
        email: "",
        report_type: "detailedanalytics"
    });
    const [overviewFilterState, setOverViewFilterState] = React.useState({
        certificate_status: [],
        course: [],
        customer: [],
        pageno: 0,
        perpage: 10,
        project: [],
        email: "",
        report_type: "detailoverview"
    });
    const [progressFilterState, setProgressFilterState] = React.useState({
        certificate_status: [],
        course: [],
        customer: [],
        pageno: 0,
        perpage: 10,
        project: [],
        email: "",
        report_type: "progressreport"
    });
    const [loading, setLoading] = React.useState(false)
    const [projectsReportData, setProjectsReportData] = React.useState(() => []);
    const [filters, setFilters] = React.useState(null);
    
    const [overViewLoading, setOverViewLoading] = React.useState(false)
    const [overViewProjectsReportData, setOverViewProjectsReportData] = React.useState(() => []);
    const [overViewFilters, setOverViewFilters] = React.useState(null);
    
    const [progressLoading, setProgressLoading] = React.useState(false)
    const [progressProjectsReportData, setProgressProjectsReportData] = React.useState(() => []);
    const [progressFilters, setProgressFilters] = React.useState();
    const [downloadReportLoading, setDownloadReportLoading] = React.useState({
        analytics: false,
        progress: false,
        overview: false
    });
    const [printReportLoading, setPrintReportLoading] = React.useState({
        analytics: false,
        progress: false,
        overview: false
    });
    const { saveAsCsv } = useJsonToCsv();

    
    const getProjectReportData = async (type, updatedFilter) => {
        setLoading(true);
        let result = await reportServices.getProjectReportData('main/detailedanalytics', updatedFilter ? updatedFilter : analyticsFilterState);
        if (result && result.status) {
            let emails = result.data?.filter?.email || [];
            emails = [...new Set(emails)];
            setFilters({...result.data.filter, email: emails.filter((itm) => itm )});
            if (userDetails.role !== USER_ROLES.SUPER_ADMIN && type === undefined && result.data.filter && result.data.filter.customer && result.data.filter.customer.length > 0) {
                setAnalyticsFilterState({ ...analyticsFilterState, customer: [result.data.filter.customer[0]] });
                getProjectReportData('analytics', { ...analyticsFilterState, customer: [result.data.filter.customer[0]] });
            } else {
                setProjectsReportData(result.data.result);
            }
        }
        setLoading(false);
    }
    
    const getProjectOverViewReportData = async (type, updatedFilter) => {
        setOverViewLoading(true);
        let result = await reportServices.getProjectReportData('main/detailedoverview', updatedFilter ? updatedFilter : overviewFilterState);
        if (result && result.status) {
            let emails = result.data?.filter?.email || [];
            emails = [...new Set(emails)];
            setOverViewFilters({ ...result.data.filter, email: emails.filter((itm) => itm ) });
            if (userDetails.role !== USER_ROLES.SUPER_ADMIN && type === undefined && result.data.filter && result.data.filter.customer && result.data.filter.customer.length > 0) {
                setOverViewFilterState({ ...overviewFilterState, customer: [result.data.filter.customer[0]] });
                getProjectOverViewReportData('overview', { ...overViewFilters, customer: [result.data.filter.customer[0]] })
            } else {
                setOverViewProjectsReportData(result.data.result);
            }
        }
        setOverViewLoading(false);
    }

    const getProjectProgressReportData = async (type, updatedFilter) => {
        setProgressLoading(true);
        let result = await reportServices.getProjectReportData('main/progressreport', updatedFilter ? updatedFilter : updatedFilter ? updatedFilter : progressFilterState);
        if (result && result.status) {
            let emails = result.data?.filter?.email || [];
            emails = [...new Set(emails)];
            setProgressFilters({ ...result.data.filter, email: emails.filter((itm) => itm ) });
            if (userDetails.role !== USER_ROLES.SUPER_ADMIN && type === undefined && result.data.filter && result.data.filter.customer && result.data.filter.customer.length > 0) {
                setProgressFilterState({ ...progressFilterState, customer: [result.data.filter.customer[0]] });
                getProjectProgressReportData('progress', { ...progressFilters, customer: [result.data.filter.customer[0]] })
            } else {
                setProgressProjectsReportData(result.data.result);
            }
        }
        setProgressLoading(false);
    }

    const getIndex = () => {
        if (type && type === 'analytic') {
            setActiveIndex(0)
        }
        else if (type === 'overview') {
            setActiveIndex(1)
        }
        else if (type === 'progress') {
            setActiveIndex(2)
        }
    }

    React.useEffect(() => {
        getProjectReportData('analytics');
        getProjectOverViewReportData();
        getProjectProgressReportData();
        getIndex()
    }, []);

    const handleItemClick = () => { }

    const handlePageChange = (evt, page) => {
        setAnalyticsFilterState({ ...analyticsFilterState, pageno: page });
        getProjectReportData('analytics', { ...analyticsFilterState, pageno: page-1 })
    }

    const handleGoToPage = (page, type) => {
        if(type === 'analytics'){
            setAnalyticsFilterState({ ...analyticsFilterState, pageno: page });
            getProjectReportData('analytics', { ...analyticsFilterState, pageno: page });
        }else if(type === 'overview'){
            setOverViewFilterState({ ...overviewFilterState, pageno: page });
            getProjectOverViewReportData('overview', { ...overviewFilterState, pageno: page })
        }else{
            setProgressFilterState({ ...progressFilterState, pageno: page });
            getProjectProgressReportData('progress', { ...progressFilterState, pageno: page });
        }
    }

    const handleOverViewPageChange = (evt, page) => {
        setOverViewFilterState({ ...overviewFilterState, pageno: page });
        getProjectOverViewReportData('overview', { ...overviewFilterState, pageno: page-1 })
    }

    const handleProgressPageChange = (evt, page) => {
        setProgressFilterState({ ...progressFilterState, pageno: page });
        getProjectProgressReportData('progress', { ...progressFilterState, pageno: page-1 });
    }

    const handleChange = (val, type, filterType) => {
        if (filterType === "analytics") {
            setAnalyticsFilterState({ ...analyticsFilterState, [type]: val });
            return getProjectReportData(filterType, { ...analyticsFilterState, [type]: val, pageno: 0 });
        } else if (filterType === "overview") {
            setOverViewFilterState({ ...overviewFilterState, [type]: val });
            return getProjectOverViewReportData(filterType, { ...overviewFilterState, [type]: val, pageno: 0 });
        } else if (filterType === "progress") {
            setProgressFilterState({ ...progressFilterState, [type]: val });
            return getProjectProgressReportData(filterType, { ...progressFilterState, [type]: val, pageno: 0 });
        }
    }

    const handleSubmitFilter = (type) => {
        if (type === 'analytics'){
            setAnalyticsFilterState({ ...analyticsFilterState, pageno: 1 });
            return getProjectReportData(type, { ...analyticsFilterState, pageno: 0 });
        }
        if (type === 'overview'){
            setOverViewFilterState({ ...overviewFilterState, pageno: 1 });
            return getProjectOverViewReportData(type, { ...overviewFilterState, pageno: 0 })
        }
        if (type === 'progress'){
            setProgressFilterState({ ...progressFilterState, pageno: 1 });
            return getProjectProgressReportData(type, { ...progressFilterState, pageno: 0 });
        }
    }

    const handleDownload = async (type) => {
        let fields = {}; 
        let columns = analyticsColumns;
        let reportType = 'download_analytics_report';
        const filterData = {
            certificate_status: [],
            course: [],
            customer: [],
            pageno: 0,
            perpage: 999999,
            project: [],
            email: "",
            report_type: reportType
        };
        let reportFilter = {...analyticsFilterState};
        if(type === 'progress'){
            reportType = 'download_progress_report';
            columns = progressColumn;
            reportFilter = {...progressFilterState};
        }else if(type === 'overview'){
            reportType = 'download_overview_report';
            columns = overViewColumn;
            reportFilter = {...overviewFilterState};
        }
        columns.map((itm) => {
            fields[itm.key] = itm.label;
        });
        if(!reportFilter?.customer?.length){
            notify('error', "Please select customer!");
            return null;
        }
        setDownloadReportLoading({...downloadReportLoading, [type]: true});
        const filename = reportType;
        filterData.certificate_status = reportFilter.certificate_status;
        filterData.customer = reportFilter.customer;
        filterData.course = reportFilter.course;
        filterData.project = reportFilter.project;
        filterData.email = reportFilter.email;
        let result = await reportServices.getProjectReportData(`main/${reportType}`, filterData);
        if (result && result.status) {
            if(result?.data && result?.data?.result){
                let data = result.data.result;
                saveAsCsv({ data, fields, filename });
            }else{
                notify('error', "Report data not found!")
            }
        }
        setDownloadReportLoading({...downloadReportLoading, [type]: false});
    }

    const handlePrint = async (type) => {
        let reportType = 'download_analytics_report';
        const filterData = {
            certificate_status: [],
            course: [],
            customer: [],
            pageno: 0,
            perpage: 999999,
            project: [],
            email: "",
            report_type: reportType
        };
        let reportFilter = {...analyticsFilterState};
        if(type === 'progress'){
            reportType = 'download_progress_report';
            reportFilter = {...progressFilterState};
        }else if(type === 'overview'){
            reportType = 'download_overview_report';
            reportFilter = {...overviewFilterState};
        }
        if(!reportFilter?.customer?.length){
            notify('error', "Please select customer!");
            return null;
        }
        setPrintReportLoading({...printReportLoading, [type]: true});
        const filename = reportType;
        filterData.certificate_status = reportFilter.certificate_status;
        filterData.customer = reportFilter.customer;
        filterData.course = reportFilter.course;
        filterData.project = reportFilter.project;
        filterData.email = reportFilter.email;
        let result = await reportServices.getProjectReportData(`main/${reportType}`, filterData);
        if (result && result.status) {
            if(result?.data && result?.data?.result){
                let columns = [
                    "Username",
                    "Learner Email",
                    "Order Type",
                    "Course Name",
                    "Course Assignment Date",
                    "Course Completion Date",
                    "Course Type",
                    "Self-Learning Completion",
                    "Project Status",
                    "OSL Score",
                    "LVC Score",
                    "Certification Status",
                    "Self Learning Time",
                    "Overall course completion score"     
                ];
                if(type === 'progress'){
                    columns = [
                        "Username",
                        "Course Name",
                        "Activity Level",
                        "Certification Status",
                        "Self-Learning Completion",
                        "Live Class Attended",
                        "Project Status",
                        "Overall course completion score"
                    ]
                }else if(type === 'overview'){
                    columns = [
                        "Username",
                        "Course Name",
                        "Activity Level",
                        "Certification Status",
                        "Course Assignment Date",
                        "Course Completion Date"
                    ]
                }
                const pdf = new jsPDF("p", "pt", "a3");
                const rows = [];
                let list = result?.data?.result;
                for (let i = 0; i < list.length; i++) {
                    let temp = [];
                    if(type === 'progress'){
                        temp = [
                            list[i].Username,
                            list[i].course,
                            list[i].activity_level,
                            list[i].certification_status,
                            list[i].self_learning_completion,
                            list[i].live_class_attended,
                            list[i].project_result,
                            list[i].test_score,
                        ];
                    }else if(type === 'overview'){
                        temp = [
                            list[i].Username,
                            list[i].course,
                            list[i].activity_level,
                            list[i].certification_status,
                            list[i].course_assignment_date,
                            list[i].course_completion_date,
                        ];
                    }else{
                        temp = [
                            list[i].Username,
                            list[i]["Learner Email"],
                            list[i].order_type,
                            list[i].program,
                            list[i].course_assignment_date,
                            list[i].course_activation_date,
                            list[i].course_type,
                            list[i].self_learning_completion,
                            list[i].project_result,
                            list[i].osl_score,
                            list[i].lvc_sore,
                            list[i].certification_score,
                            list[i].self_learning_time,
                            list[i].test_score
                          ];
                    }
                    rows.push(temp);
                }
                pdf.autoTable(columns, rows, {
                    startY: 65,
                    theme: "grid",
                    styles: {
                    font: "times",
                        halign: "center",
                        cellPadding: 2,
                        lineWidth: 0.5,
                        lineColor: [0, 0, 0],
                        textColor: [0, 0, 0]
                    },
                    headStyles: {
                        textColor: [0, 0, 0],
                        fontStyle: "normal",
                        lineWidth: 0.5,
                        lineColor: [0, 0, 0],
                        fillColor: [166, 204, 247]
                    },
                    alternateRowStyles: {
                        fillColor: [212, 212, 212],
                        textColor: [0, 0, 0],
                        lineWidth: 0.5,
                        lineColor: [0, 0, 0]
                    },
                    rowStyles: {
                        lineWidth: 0.5,
                        lineColor: [0, 0, 0]
                    },
                    tableLineColor: [0, 0, 0]
                });
                pdf.save(filename);
            }else{
                notify('error', "Report data not found!")
            }
        }
        setPrintReportLoading({...printReportLoading, [type]: false});
    }

    return (
        <Layout>
            <main>
                <div className={commonClasses.contentWrapper}>
                    <CustomTabs
                        activeIndex={activeIndex}
                        tabs={[
                            {
                                label: 'Detailed analytics report',
                                children: (
                                    <DetailsAnalyticsTab
                                        type="analytics"
                                        title="Detailed Analytics"
                                        filters={filters}
                                        pageName='learning'
                                        allFilterState={analyticsFilterState}
                                        handleChange={handleChange}
                                        columns={analyticsColumns}
                                        loading={loading}
                                        handleItemClick={handleItemClick}
                                        list={projectsReportData}
                                        label="Detailed analytics report"
                                        handlePageChange={handlePageChange}
                                        handleSubmitFilter={handleSubmitFilter}
                                        handleGoToPage={handleGoToPage}
                                        handleDownload={() => handleDownload('analytics')}
                                        handlePrint={() => handlePrint('analytics')}
                                        downloadReportLoading={downloadReportLoading}
                                        printReportLoading={printReportLoading}
                                    />
                                )
                            },
                            {
                                label: 'Overview report',
                                children: (
                                    <DetailsAnalyticsTab
                                        type="overview"
                                        title="Overview Report"
                                        filters={overViewFilters}
                                        pageName='learning'
                                        allFilterState={overviewFilterState}
                                        handleChange={handleChange}
                                        columns={overViewColumn}
                                        loading={overViewLoading}
                                        handleItemClick={handleItemClick}
                                        list={overViewProjectsReportData}
                                        label="Overview report"
                                        handlePageChange={handleOverViewPageChange}
                                        handleSubmitFilter={handleSubmitFilter}
                                        handleGoToPage={handleGoToPage}
                                        downloadReportLoading={downloadReportLoading}
                                        handlePrint={() => handlePrint('overview')}
                                        handleDownload={() => handleDownload('overview')}
                                        printReportLoading={printReportLoading}
                                    />
                                )
                            },
                            {
                                label: 'Progress report',
                                children: (
                                    <DetailsAnalyticsTab
                                        type="progress"
                                        title="Progress Report"
                                        filters={progressFilters}
                                        pageName='learning'
                                        allFilterState={progressFilterState}
                                        handleChange={handleChange}
                                        columns={progressColumn}
                                        loading={progressLoading}
                                        handleItemClick={handleItemClick}
                                        list={progressProjectsReportData}
                                        label="Progress report"
                                        handlePageChange={handleProgressPageChange}
                                        handleSubmitFilter={handleSubmitFilter}
                                        handleGoToPage={handleGoToPage}
                                        downloadReportLoading={downloadReportLoading}
                                        handleDownload={() => handleDownload('progress')}
                                        handlePrint={() => handlePrint('progress')}
                                        printReportLoading={printReportLoading}
                                    />
                                )
                            }
                        ]}
                    />
                </div>
            </main>
        </Layout>
    );
}

export default Report;