import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import GenericTable from '../../../../Components/GenericTable';
import { Pagination } from '@material-ui/lab';
import UserBulkUpload from '../../../Users/components/userBulkUpload'
import { CircularProgress, Button, Typography, IconButton } from '@material-ui/core/';
import { useParams } from "react-router-dom";
import { useStyle as onboardingStyle } from '../..';
import { checkValidEmail, notify } from 'utils';
import { UserServices } from '../../../../Services/UserServices';
import { ProjectServices } from '../../../../Services/ProjectServices';
import CreateUserForm from 'Pages/Users/components/CreateUserForm';
import { useAuthDispatch, useAuthState } from '../../../../Context';
import { REQUIRED_ERROR } from '../../../../utils/constants';
import { initFormState } from '../CustomerAdmin';
import variables from '../../../../Sys/variable.scss';
import AssignUserModal from './AssignUserModal';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';

const userServices = new UserServices();
const projectServices = new ProjectServices();

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        borderColor: '#838383',
        borderRadius: '25px',
    },
    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '25px 0'
    },
    heading: {
        color: variables.labelcolor,
        fontSize: '23px',
        fontWeight: '500'
    },
    btn: {
        padding: '13px 35px',
        background: '#3D7DDD',
        fontSize: '17px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
        "&:hover": {
            background: "#3d7ddd99"
        }
    },
    placeBtn: {
        display: 'flex',
        justifyContent: 'end',
        padding: '20px 0'
    },
    btnPwd: {
        background: '#3D7DDD',
        color: 'white',
        borderRadius: '25px',
        padding: '18px',
        marginTop: '-3px',
        marginLeft: '-42px',
        "&:hover": {
            background: "#3D7DDD"
        }
    }
}));

const columns = [
    { label: "First name", key: 'firstName' },
    { label: "Surname", key: 'lastName' },
    { label: "Username", key: 'userName' },
    { label: "Location", key: "location" },
    { label: "Email", key: "email" },
    { label: "User Role", key: "role" },
    { label: "Action", key: "action", actions: ["edit"] }
];

const roleList = [
    {
        id: 'projectadmin',
        name: 'Project Admin'
    },
    {
        id: 'user',
        name: 'User'
    },
    {
        id: 'csmadmin',
        name: 'CSM'
    }
];

const Users = ({
    activeStep,
    handleBack,
    handleNext,
    formBackupState
}) => {

    const onBorningClasses = onboardingStyle();
    const classes = useStyles();

    let { id } = useParams();
    const dispatch = useAuthDispatch();
    const { userDetails } = useAuthState(dispatch);

    const [openBulkUploadDialog, setOpenBulkUploadDialog] = useState(false);
    const [openAssignUser, setOpenAssignUser] = useState(false);
    const [projectUserList, setProjectUserList] = useState([]);
    const [userFilterState, setUserFilterState] = useState({ page: 1, search: "" });
    const [userPagination, setUserPagination] = useState(null);
    const [userLoading, setUserLoading] = useState(false);
    const [showCreateUser, setShowCreateUser] = useState(false);
    const [companyList, setCompanyList] = React.useState([]);
    const [editCustomers, setEditCustomers] = React.useState([]);
    const [formState, setFormState] = React.useState([{ ...initFormState, is_active: "", customers: [] }]);
    const [editUserId, setEditUserId] = useState(null);
    const [submitLoading, setSubmitLoading] = React.useState(false);
    const [queryData, setQueryData] = useState({ search: '' });
    const [assignedUserList, setAssignedUserList] = useState([]);
    const [assignUserLoading, setAssignUserLoading] = useState(false);
    const [editFormState, setEditFormState] = React.useState({ ...initFormState, is_active: "" });

    useEffect(() => {
        localStorage.setItem('userbyproject', true);
        let project_id = localStorage.getItem('project_id');
        if (project_id) {
            getProjectUser(project_id);
        }
    }, []);

    async function getProjectUser(project_id) {
        setUserLoading(true);
        const projectUserRes = await projectServices.userByProject(project_id);
        if (projectUserRes && projectUserRes.data) {
            setProjectUserList(projectUserRes.data);
        }
        setUserLoading(false);
    }

    const getAllUser = async (queryData) => {
        setAssignUserLoading(true);
        let query = `search=${queryData.search}&page=1`;
        const userResult = await userServices.getAllUser(`main/userlist/all?${query}`);
        setAssignedUserList(userResult?.data || []);
        setAssignUserLoading(false);
    };

    const handlePageChange = (evt, page) => {
        if (activeStep === 4) {
            setUserFilterState({ ...userFilterState, page });
            // getAllUser({ ...userFilterState, page });
        }
    };

    const handleItemClick = (row) => {
        if (!row) return null;
        setEditFormState({
            firstName: row.firstName,
            lastName: row.lastName,
            email: row.email,
            userName: row.userName,
            role: row.role,
            location: row.location,
            password: "",
            is_active: row.user__is_active
        });
        let selectedComp = companyList.filter(itm => row?.company_ids.includes(itm.uuid));
        setEditCustomers(selectedComp);
        setShowCreateUser(true);
        setEditUserId(row.uuid);
    }

    const handleCreateNew = () => {
        setShowCreateUser(true);
    }

    const handleBulkUploadDialog = () => {
        setOpenBulkUploadDialog(true)
    }

    const handleInputChange = (evt, idx) => {
        if (editUserId) {
            const { name, value } = evt.target;
            setEditFormState({ ...editFormState, [name]: value });
        } else {
            const { name, value } = evt.target;
            setFormState((prevState) => {
                let newState = [...prevState];
                newState[idx][name] = value;
                return newState;
            });
        }
    }

    React.useEffect(() => {
        getCompanyList();
    }, []);

    // React.useEffect(() => {
    //     if (formBackupState) {
    //         setFormState({ ...formBackupState });
    //     }
    // }, [formBackupState]);

    const getCompanyList = async () => {
        if (!id) {
            id = localStorage.getItem('customerUUID');
        }
        const compResult = await userServices.customerDropdown(id);
        if (compResult && compResult.data) {
            localStorage.setItem('getAllCustomer', false);
            setCompanyList(compResult.data);
        }
    }

    const handleChangeCustomer = (value, idx) => {
        if (editUserId) {
            setEditCustomers(value);
        } else {
            setFormState((prevState) => {
                let newState = [...prevState];
                newState[idx]['customers'] = value;
                return newState;
            });
        }
    }

    const formValidationCheck = (evt) => {
        if(!editUserId){
            let valid = true;
            let emailValid = true;
            let errorEmails = [];
            let duplicatesEmails = [];
            let duplicateUserName = [];
            formState.map((item) => {
                if (!item.firstName || !item.lastName || !item.userName || !item.email || !item.location) {
                    valid = false
                }
                if(!checkValidEmail(item.email)){
                    emailValid = false;
                    errorEmails.push(item.email);
                }
                let checkDuplicateEmail = formState.filter(itm => (itm.email === item.email));
                if(checkDuplicateEmail.length > 1){
                    duplicatesEmails.push(item.email);
                }
                let checkDuplicateUserNm = formState.filter(itm => (itm.userName === item.userName));
                if(checkDuplicateUserNm.length > 1){
                    duplicateUserName.push(item.userName);
                }
            });
            if(!valid){
                notify("error", REQUIRED_ERROR);
                return null;
            }

            if(!emailValid){
                notify('error', `Please enter valid email address, ${errorEmails.length ? errorEmails?.join(", ") : ""}`);
                return null;
            }
            if(duplicatesEmails.length){
                notify('error', `Emails are duplicates, ${duplicatesEmails?.join(", ")}`);
                return null;
            }
            if(duplicateUserName.length){
                notify('error', `Username are duplicates, ${duplicateUserName?.join(", ")}`);
                return null;
            }
        }else{
            if (!editFormState.firstName || !editFormState.lastName || !editFormState.userName || !editFormState.email) {
                notify("error", REQUIRED_ERROR);
                return null;
            }
        }
        return handleFormSubmit(evt);
    }

    const handleFormSubmit = async (evt) => {
        setSubmitLoading(true);
        let uuid = null;
        let payload = null;
        let project_id = localStorage.getItem('project_id');
        if (editUserId) {
            uuid = editUserId;
            let customersIds = editCustomers.map(cs => cs.uuid);
            payload = { ...editFormState, customers: customersIds, updates: 'true' };
            delete editFormState.password;
        } else {
            uuid = null;
            payload = formState.map(itm => {
                let tmpObj = {
                    ...itm,
                    customers: itm?.customers?.map(cs => cs.uuid) || [],
                    is_active: true
                }
                if (project_id) {
                    tmpObj.project_id = project_id;
                }
                return tmpObj;
            });
        }
        const result = await userServices.addMultipleUser(payload, uuid);
        if (result && result.status) {
            notify("success", result.message)
            setSubmitLoading(false);
            setShowCreateUser(false);
            setFormState([{...initFormState, is_active: "", customers: []}]);
            setEditUserId(null);
            setEditFormState({...initFormState, is_active: ""});
            setEditCustomers([]);
            handleNext(evt);
        } else {
            setSubmitLoading(false);
            let errorMsg = "Something Went Wrong"; 
            if(result.message && result.message?.email_errors?.length){
                errorMsg = result.message?.email_errors[0];
            }else if(result.message && result.message?.username_errors?.length){
                errorMsg = result.message?.username_errors[0];
            }
            notify("error", errorMsg);
        }
        setSubmitLoading(false);
    }

    const handleRemoveUser = (idx) => {
        setFormState((prevState) => {
            let newState = [...prevState];
            newState.splice(idx, 1); 
            return newState;
        });
    } 

    const handleAssignUserToProject = async (users) => {
        console.log("Assign User");
        let project_id = localStorage.getItem('project_id');
        console.log(users, project_id);
        if(users && users.length && project_id){
            const payload = {
                projectId: project_id,
                userIds: [...users]
            };
            const assignRes = await projectServices.assignProjectToUsers(payload);
            console.log("assignRes: ", assignRes);
            if (assignRes && assignRes.status) {
                notify('success', "Project assigned successfully!");
                setOpenAssignUser(false);
                setQueryData({search: ''});
                return getProjectUser(project_id);
            }
        }
    }

    React.useEffect(() => {
        const getData = setTimeout(() => {
            getAllUser({ page: 1, search: queryData.search });
        }, 800);
        return () => clearTimeout(getData);
    }, [queryData.search]);


    return (
        <>
            <div style={showCreateUser ? { display: 'none' } : null}>
                <Grid container style={{ paddingTop: 20 }}>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={4} style={{ display: "flex" }} justifyContent={'space-between'} >
                        <Button
                            align={'right'}
                            variant={'outlined'}
                            style={{ padding: '15px 35px', borderRadius: 5 }}
                            color="primary"
                            type='button'
                            onClick={handleBulkUploadDialog}
                        >
                            Bulk Upload
                        </Button>
                        <Button
                            align={'right'}
                            variant={'outlined'}
                            style={{ padding: '15px 35px', marginLeft: 10, borderRadius: 5 }}
                            color="primary"
                            type='button'
                            onClick={(evt) => {
                                setOpenAssignUser(true);
                                getAllUser({ ...queryData });
                            }}
                        >
                            Assign Users
                        </Button>
                        <Button
                            align={'right'}
                            variant={'outlined'}
                            style={{ padding: '15px 35px', marginLeft: 10, borderRadius: 5 }}
                            color="primary"
                            type='button'
                            onClick={handleCreateNew}
                        >
                            Create Users
                        </Button>
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: 20 }}>
                    <GenericTable
                        columns={columns}
                        loading={userLoading}
                        handleItemClick={handleItemClick}
                        list={projectUserList}
                    />
                </Grid>
                <Grid container style={{ marginTop: 22 }} >
                    {userPagination &&
                        <Pagination
                            onChange={handlePageChange}
                            style={{ margin: "0 auto" }}
                            count={parseInt(Math.ceil(userPagination.total_entries / userPagination.end_index))}
                            variant="outlined"
                            shape="rounded"
                            siblingCount={0}
                        />}
                </Grid>
                <div className={onBorningClasses.buttons}>
                    <div className={onBorningClasses.wrapper}>
                        {activeStep !== 0 && (
                            <Button
                                onClick={handleBack}
                                variant="contained"
                                color="primary"
                                className={onBorningClasses.cancelBtn}>
                                Back
                            </Button>
                        )}
                        <Button
                            type={"button"}
                            onClick={handleNext}
                            variant="contained"
                            color="primary"
                            className={onBorningClasses.button}
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </div>
            {showCreateUser &&
                <div>
                    {editUserId ?
                        <CreateUserForm
                            formState={editFormState}
                            handleChangeCustomer={handleChangeCustomer}
                            handleInputChange={handleInputChange}
                            companyList={companyList}
                            customers={editCustomers}
                            roleList={roleList}
                            userNameDisable
                            emailDisable
                            userDetails={userDetails}
                            isOnbaording
                        />
                        :
                        formState.map((itm, index) => (
                            <>
                                <Divider style={{ marginTop: 15, marginBottom: 10 }} />
                                <Grid container>
                                    <Grid item xs={1}>
                                        <Typography variant="h4">User {index + 1}</Typography>
                                    </Grid>
                                    {index > 0 &&
                                    <Grid item xs={1}>
                                        <IconButton aria-label="delete" onClick={() => handleRemoveUser(index)} ><DeleteIcon style={{color: 'red'}}/></IconButton>
                                    </Grid>}
                                </Grid>
                                <CreateUserForm
                                    key={index}
                                    formState={itm}
                                    handleChangeCustomer={evt => handleChangeCustomer(evt, index)}
                                    handleInputChange={evt => handleInputChange(evt, index)}
                                    companyList={companyList}
                                    roleList={roleList}
                                    userDetails={userDetails}
                                    isOnbaording
                                />
                            </>
                        ))}
                    <div className={onBorningClasses.buttons}>
                        <div className={onBorningClasses.wrapper}>
                            {!editUserId &&
                                <Button
                                    variant={'contained'}
                                    className={onBorningClasses.button}
                                    color="primary"
                                    type='button'
                                    onClick={() => {
                                        setFormState([...formState, { ...initFormState, is_active: "", customers: [] }]);
                                    }}
                                >
                                    Add new
                                </Button>}
                            <Button
                                onClick={(evt) => {
                                    setFormState([{ ...initFormState, is_active: "", customers: [] }]);
                                    setEditFormState({ ...initFormState, is_active: "" });
                                    setEditUserId(null);
                                    setShowCreateUser(false);
                                }}
                                style={{ marginRight: 10 }}
                                variant="contained"
                                color="primary"
                                className={onBorningClasses.cancelBtn}>
                                Cancel
                            </Button>
                            {activeStep !== 0 && (
                                <Button
                                    onClick={handleBack}
                                    variant="contained"
                                    color="primary"
                                    className={onBorningClasses.cancelBtn}>
                                    Back
                                </Button>
                            )}
                            <Button
                                type={"button"}
                                onClick={formValidationCheck}
                                variant="contained"
                                color="primary"
                                disabled={submitLoading}
                                className={onBorningClasses.button}
                            >
                                {submitLoading ? (
                                    <CircularProgress
                                        size={18}
                                        className={classes.buttonProgress}
                                    />
                                ) : 'Next'}
                            </Button>
                        </div>
                    </div>
                </div>
            }
            <UserBulkUpload
                open={openBulkUploadDialog}
                uuid={id || localStorage.getItem('customerUUID')}
                handleClose={() => {
                    setOpenBulkUploadDialog(false);
                    let project_id = localStorage.getItem('project_id');
                    if (project_id) {
                        getProjectUser(project_id);
                    }
                }}
            />
            <AssignUserModal
                open={openAssignUser}
                loading={assignUserLoading}
                list={assignedUserList}
                handleSubmit={handleAssignUserToProject}
                queryData={queryData}
                handleChange={(evt) => setQueryData({ search: evt.target.value })}
                handleSearch={evt => {
                    evt.preventDefault();
                    return getAllUser(queryData);
                }}
                handleClose={() => {
                    setOpenAssignUser(false);
                    setAssignedUserList([]);
                }}
            />
        </>
    );
}

export default Users;