import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import Immutable from 'seamless-immutable';

export * from './serialize'

export { Immutable };
export const isImmutable = v => Immutable.isImmutable(v);

export const fromJS = (v, opts = {}) =>
  isImmutable(v) ? v : Immutable(v, opts);
export const deep = { deep: true };

export const toJS = s =>
  !!s && isImmutable(s)
    ? typeof s === 'object'
      ? s.asMutable && s.asMutable({ deep: true })
      : s
    : s;

export const notify = (type, content) => {
  return toast(content, { type });
}

export const dateFormatterLLL = input => moment(input).format('LLL');
export const dateFormatterLLLHHMMSS = input => moment(input).format("ll hh:mm:ss A");

export const generatePassword = length => {
  var passwd = '';
  var chars =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$*';
  for (let i = 0; i < length; i++) {
    var c = Math.floor(Math.random() * chars.length + 1);
    passwd += chars.charAt(c);
  }
  return passwd;
};

export const checkValidEmail = email => {
  if (!email) return false;
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const checkValidURL = userInput => {
  const res = userInput.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res != null;
};

export const IsNumeric = num => /^[0-9]+([.][0-9]+)?$/.test(num);

export const removeSpacialCharecter = text => {
  return text.replace(/[&\/\\#,+()$~%.'":*?<>{}!@^|;_`]/g, '');
};

export const convertPriceFormat = x => {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};
export const getDeep = (src, key, _def = false) =>
  toJS(Immutable.getIn(src, key.split('.'), _def));

export const downloadCSVReport = async (url, fileName, filterobj) => {
  return axios
    .put(url, filterobj)
    .then(blob => {
      if (!blob.data.status) {
        notify("error", blob.data.message);
        console.log(blob.data.message);
        return;
      }
      const csv = atob(blob.data.data);
      let mType = fileName.endsWith('.csv')
        ? { type: 'text/csv;charset=utf-8;' }
        : { type: 'application/pdf;' };

      let csvData = new Blob([csv], { type: mType });
      saveAs(csvData, `${fileName}`);
      console.log('Report saved...');
    })
    .catch(err => {
      console.log(err);
      notify("error", err.response.data.message);
    });
};


const isOb = value => !!value && typeof value === 'object',
  isFn = value => !!value && typeof value === 'function';

export const isObject = value => !!value && (isOb(value) || isFn(value)),
  isString = value => !!value && typeof value === 'string',
  isNumber = value => !!value && typeof value === 'number' && !isNaN(value),
  isValidNumber = value => isNumber(value) && !isNaN(value),
  isFunction = value => !!value && isFn(value),
  isPlainObject = value => isOb(value) && !isFn(value),
  isArray = Array.isArray

export const every = (args, predicate) =>
  args.filter(v => predicate(v)).length === args.length,
  is2DArray = v => (isArray(v) ? isArray(v[0]) : false),
  isCollection = v => (isArray(v) ? isObject(v[0]) : false),
  isModel = (v, ident = 'pk') => isObject(v) && ident in v,
  isModelCollection = (ident = 'pk') => v =>
    isArray(v) ? isModel(v[0], ident) : false,
  allArrays = (...args) => every(args, isArray),
  allObjects = (...args) => every(args, isObject),
  allCollection = (...args) => every(args, isCollection),
  allModelCollection = (ident = 'pk') => (...args) =>
    every(args, isModelCollection(ident)),
  allModels = (ident = 'pk') => (...args) =>
    every(args, v => isObject(v) && ident in v)

export const resetThemeColors = (colors) => {
  document.documentElement.style.setProperty(
    '--primaryColor',
    colors?.primary_color || '#3D7DDD'
  );
  document.documentElement.style.setProperty(
    '--buttonHoverColor',
    colors?.buttonHover_color || '#083f93'
  );
  document.documentElement.style.setProperty(
    '--sidebarColor',
    colors?.sidebar_color || '#FAFCFF'
  );
  document.documentElement.style.setProperty('--bodyColor', colors?.body_color || '#f5f5f5');
  document.documentElement.style.setProperty(
    '--contentColor',
    colors?.contentColor || '#fff'
  );
  document.documentElement.style.setProperty('--headerColor', colors?.headerColor || '#ffffff');
  
  document.documentElement.style.setProperty(
    '--secondryColor',
    colors?.secondary_color || '#EC7A12'
  );
  document.documentElement.style.setProperty(
    '--successMessageBGColor',
    colors?.successMessageBG_color || '#ffffff'
  );
  document.documentElement.style.setProperty(
    '--sidebarActive',
    colors?.sidebarActive || '#3D7DDD'
  );
  document.documentElement.style.setProperty(
    '--labelcolor',
    colors?.label_color || '#4D5766'
  );
  document.documentElement.style.setProperty('--h1Size', colors?.h1size || '10px');
  document.documentElement.style.setProperty('--h2Size', colors?.h2size || '24px');
  document.documentElement.style.setProperty('--h3Size', colors?.h3size || '18px');
  document.documentElement.style.setProperty('--pSize', colors?.psize || '16px');
  document.documentElement.style.setProperty('--darkThemeText', colors?.darkThemeText || '#4D5766');
}

export const getEncodeURL = (url) => {
  let encodedURL = encodeURI(url);
  return url.includes(" & ") ? encodedURL.replace('&', '%26') : encodedURL;
}
