import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../../../../Components/layouts";
import { SkillsAndTransformation } from 'Services/SkillsAndTransformation';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import { makeFetcher } from 'Services/ThirdPartyService';
import usersImg from '../../../../Assets/images/deviare-minimum-logo.png';
import { calculatePercentage } from '../RegisteredCourses';
import { BorderLinearProgress, BorderLinearProgressComplete } from 'Pages/UserAdmin/components/DynamicCard';

let skillsAndTransformation = new SkillsAndTransformation()

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: 180,
        background: '#FFFFFF',
        boxShadow: "0px 4px 30px rgba(12, 41, 84, 0.1)",
        borderRadius: 5,
        width: '100%',
    },
    content: {
        flex: '1 0 auto',
        maxWidth: '80%',
        alignSelf: 'center',
    },
    cover: {
        width: 135,
        height: 140,
        paddingTop: 17,
        marginTop: 25,
        marginLeft: 20,
        borderRadius: 5,
        maxWidth: '20%',
        marginRight: 40
    },
    cardTitle: {
        color: "#808080"
    },
    cardDesc: {
        color: "#808080",

    },
}));

export function ApprenticeshipCard({ item, handleLinkURL }){
    const classes = useStyles();
    const desc = "Work with some of the leading digital businesses and global entrepreneurs to accelerate your digital career.";
    let value = item?.value || 0;
    let total = item?.total || 0;
    let progress = item?.progress || true;

    return (
        <Grid key={"appr"} item xs={item?.width}>
            <Card className={classes.root} sx={{ display: "flex" }}>
                <CardMedia
                    className={classes.cover}
                    image={usersImg}
                    title={item?.name || 'Virtual Global Apprenticeship'}
                />
                <CardContent className={classes.content} style={{ display: 'flex' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={9}>
                            <Typography className={classes.cardTitle}>
                                {'Virtual Global Apprenticeship Progress'}
                            </Typography>
                            <Typography variant="h1" color="primary">
                                {'Virtual Global Apprenticeship'}
                            </Typography>
                            <Typography className={classes.cardDesc} title={desc}>
                                {desc}
                            </Typography>
                            <Typography variant="h1" color="primary">
                                {item?.totalCount}
                            </Typography>

                            {progress ? calculatePercentage(value, total) !== 100 ? <BorderLinearProgress variant="determinate" value={calculatePercentage(value || 0, total || 0)} /> :
                                <BorderLinearProgressComplete variant="determinate" value={calculatePercentage(value || 0, total || 0)} /> : null}

                            <Typography style={{ fontSize: 13, color: "#808080", paddingTop: 6 }} color="primary">
                                {calculatePercentage(value || 0, total || 0) + "% " + "completed"}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} style={{margin: 'auto'}}>
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={item === null}
                                onClick={() => handleLinkURL(item)}
                                style={{ width: '100%', padding: '15px 45px', borderRadius: 10 }}>
                                {value > '0' ? (
                                    "Continue learning"
                                ) : (
                                    "LAUNCH"
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}

const VirtualGlobalApprenticeship = () => {

    const [loader, setLoader] = React.useState(false);
    const [registeredApprenticeshipsList, setRegisteredApprenticeshipsList] = React.useState([]);

    React.useEffect(() => {
        setLoader(true);
        getRegisteredApprenticeships();
    }, []);

    const getRegisteredApprenticeships = async () => {
        let regApp = await skillsAndTransformation.getRegisteredApprenticeships()
        if (regApp && regApp.status) {
            setRegisteredApprenticeshipsList(regApp.data.data);
        }
        setLoader(false);
    }

    const fetcher = makeFetcher('main/vga');

    const handleLinkURL = ({ link }) => {
        const session_data = localStorage.getItem('session_data');
        fetcher
            .fget('main/vga', { link, session_data })
            .then(({ data }) => {
                if (data.url) {
                    window.open(data.url, '_new');
                }
            })
            .catch(err => console.log(err));
    };

    return (
        <Layout>
            <main>
                <Grid container spacing={2}>
                    {/* {loader && <div style={{ marginTop: 30, textAlign: "center", width: "100%" }}>
                        <CircularProgress />
                    </div>} */}
                    <ApprenticeshipCard item={ registeredApprenticeshipsList.length ? {...registeredApprenticeshipsList[0], total: 100, value: 0, progress: true } : null} handleLinkURL={handleLinkURL} />
                    
                </Grid>
            </main>
        </Layout >
    );
}

export default VirtualGlobalApprenticeship;