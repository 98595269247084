import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../../Components/layouts";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import customerImg from '../../Assets/images/customer.png';
import usersImg from '../../Assets/images/users.png';
import projectsImg from '../../Assets/images/projects.png';
import compProjImg from '../../Assets/images/complete-projects.png';
import Typography from '@material-ui/core/Typography';
import BarChartWrapper from '../../Components/Charts/BarChartWrapper';
import GenericTable from '../../Components/GenericTable';
import ChartContainer from '../../Components/Charts/ChartContainer';
import { Dashboard } from '../../Services/DashboardService';
import { useAuthDispatch, useAuthState } from '../../Context';
import { USER_ROLES } from '../../utils/constants';
import { useHistory } from 'react-router';
import { UserPath } from 'Routes/routePaths';
import { isEmpty } from 'lodash';
import variables from '../../Sys/variable.scss';
import { useState } from 'react';

const dashboardService = new Dashboard();

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: 180,
        background: variables.sidebarColor,
        boxShadow: "0px 4px 30px rgba(12, 41, 84, 0.1)",
        borderRadius: 5
    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 174,
        height: 132,
        paddingTop: 17,
        marginTop: 25,
        marginLeft: 20,
        borderRadius: 5
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    cardTitle: {
        color: variables.labelcolor
    },
    cardSubTitle: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        color: "#3D7DDD",
    }
}));

const DashBoard = () => {
    
    const dispatch = useAuthDispatch();
    const history = useHistory();
    const { userDetails } = useAuthState(dispatch);
    const [dashboardCounts, setDashboardCounts] = useState({ totalCustomer: 0, totalProjects: 0, totalUser: 0, totalClosedProjects: 0, totalOpenProjects: 0, totalUsedLicenses: 0, totalCourses: 0, totalAssesment: 0, totalLicense: 0, totalCourseRequest: 0 });
    const [monthlyVisitorData, setMonthlyVisitorData] = useState([]);
    const [customerList, setCustomerList] = useState([]);

    React.useEffect(() => {
        let userRole = userDetails?.role || USER_ROLES.USER;
        if(userRole === USER_ROLES.USER){
            history.push(UserPath.UserDashboard);
        }
        if(userDetails){
            getDashboardDetails();
            getReportsGraphData();
            getTopUsersList()
        }
    }, [userDetails]);

    const getDashboardDetails = async () => {
        let userCount = 0;
        if (userDetails.role === USER_ROLES.SUPER_ADMIN) {
            const superAdminDashboard = await dashboardService.getDetailDashboardSuperAdmin();
            if (superAdminDashboard && superAdminDashboard.status && !isEmpty(superAdminDashboard.data)) {
                const { total_users } = superAdminDashboard.data;
                userCount = total_users;
            }
        }
        const dashBoardData = await dashboardService.getDetailDashboardCustomer();
        if (dashBoardData && dashBoardData.status && !isEmpty(dashBoardData.data)) {
            const { customer_count, open_project_count, closed_project_count, course_count, used_license_count, total_license_count, assessment_licenses, course_request_count, license_count } = dashBoardData.data;
            let projects = open_project_count+closed_project_count;
            setDashboardCounts({ totalUser: userCount, totalCustomer: customer_count, totalProjects: projects, totalLicense: total_license_count || license_count, totalClosedProjects: closed_project_count, totalOpenProjects: open_project_count, totalUsedLicenses: used_license_count, totalCourses: course_count, totalAssesment: assessment_licenses, totalCourseRequest: course_request_count });
        }
    };

    const getTopUsersList = async () => {
        const userList = await dashboardService.getTopCustomerAndUsers();
        console.log("userList: ", userList);
        if(userList?.data && userList?.data.length){
            setCustomerList(userList.data.map((crs) => {
                return {
                    username: crs.name,
                    email: crs.email,
                    // course_activation_date: crs.project_start_date,
                    license_count: crs.license_count,
                    date: crs.project_start_date
                };
            }));
        }
    } 

    const getReportsGraphData = async () => {
        const graphData = await dashboardService.getPopularCourseGraph();
        if(graphData?.data && graphData?.data.length){
            setMonthlyVisitorData(graphData.data.map((crs) => {
                return { name: crs.course_name, count: crs.count };
            }));

        }
    };

    const cardData = [
        {
            title: 'Total Customers',
            subTitle: 'Customers',
            image: customerImg,
            totalCount: dashboardCounts.totalCustomer,
        },
        {
            title: 'Total Projects',
            subTitle: 'Projects',
            image: projectsImg,
            totalCount: dashboardCounts.totalProjects,
        }, {
            title: 'Total Users',
            subTitle: 'Users',
            image: usersImg,
            totalCount: dashboardCounts.totalUser,
        },
        {
            title: 'Total Licenses',
            subTitle: 'Licenses',
            image: compProjImg,
            totalCount: dashboardCounts.totalLicense,
        },
        {
            title: 'Total Completed Projects',
            subTitle: 'Completed Projects',
            image: projectsImg,
            totalCount: dashboardCounts.totalClosedProjects,
        },
        {
            title: 'Total Open Projects',
            subTitle: 'Open Projects',
            image: compProjImg,
            totalCount: dashboardCounts.totalOpenProjects,
        },
        {
            title: 'Total Number of Used Licenses for Customer',
            subTitle: 'Used Licenses for Customer',
            image: usersImg,
            totalCount: dashboardCounts.totalUsedLicenses
        }
    ];

    const customerCardData = [
        {
            title: 'Total Open Projects',
            subTitle: 'Open Projects',
            image: customerImg,
            totalCount: dashboardCounts.totalOpenProjects,
        },
        {
            title: 'Total Closed Projects',
            subTitle: 'Closed Projects',
            image: projectsImg,
            totalCount: dashboardCounts.totalClosedProjects,
        }, {
            title: 'Total Course Requests',
            subTitle: 'Course Requests',
            image: usersImg,
            totalCount: dashboardCounts.totalCourseRequest,
        }, {
            title: 'Skills',
            subTitle: 'Skills Transformation',
            image: compProjImg,
            totalCount: '0',
        },
        {
            title: 'Number Of Deployments',
            subTitle: 'Scale',
            image: customerImg,
            totalCount: '0',
        },
        {
            title: 'Lab Hours',
            subTitle: 'Test And Experiments',
            image: projectsImg,
            totalCount: '0',
        }, {
            title: 'Assessments Due',
            subTitle: 'Assessments',
            image: usersImg,
            totalCount: dashboardCounts.totalAssesment,
        }, {
            title: 'License Counts',
            subTitle: 'Total License Counts',
            image: compProjImg,
            totalCount: dashboardCounts.totalLicense,
        }
    ];

    let cards = (userDetails.role === USER_ROLES.SUPER_ADMIN) ? cardData : customerCardData;

    const classes = useStyles();
    return (
        <Layout>
            <main>
                <Grid container spacing={2}>
                    {cards.map((item) => (
                        <Grid key={item.title} item xs={6}>
                            <Card className={classes.root}>
                                <CardMedia
                                    className={classes.cover}
                                    image={item.image}
                                    title={item.title}
                                />
                                <CardContent className={classes.content}>
                                    <Typography className={classes.cardTitle}>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="h1" color="primary">
                                        {item.subTitle}
                                    </Typography>
                                    <Typography variant="h1" color="primary">
                                        {item.totalCount}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                {userDetails.role === USER_ROLES.SUPER_ADMIN ? (
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <ChartContainer
                                styleOveride={{ background: variables.sidebarColor, borderRadius: 12, marginTop: 32, padding: 10 }}
                                title={"Monthly Visitor"}
                            >
                                <BarChartWrapper data={monthlyVisitorData} />
                            </ChartContainer>
                        </Grid>
                        <Grid item xs={8}>
                            <ChartContainer
                                styleOveride={{ background: variables.sidebarColor, borderRadius: 12, marginTop: 32, padding: 10 }}
                                title={"Top Customers/users"}
                            >
                                <GenericTable
                                    columns={[{ label: "Username", key: 'username' }, { label: "Learner Email", key: "email" }, { label: "License count", key: "license_count" }, { label: "Date", key: "date" }]}
                                    applyMinWidth={false}
                                    list={customerList}
                                />
                            </ChartContainer>
                        </Grid>
                    </Grid>
                ) : null}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ChartContainer
                            styleOveride={{ background: variables.sidebarColor, borderRadius: 12, marginTop: 32, padding: 10 }}
                            title={userDetails.role === USER_ROLES.SUPER_ADMIN ? "Check Customer Growth" : "Check Courses Growth"}
                        >
                            <BarChartWrapper data={monthlyVisitorData} />
                        </ChartContainer>
                    </Grid>
                </Grid>
            </main>
        </Layout>
    );
}

export default DashBoard;