import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { Button, makeStyles, TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/EditOutlined';
import variables from '../../Sys/variable.scss';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  editButton: {
    color: "#fff",
    background: "#06AD17",
    boxShadow: "0px 15.8892px 39.723px rgb(191 21 108 / 5%)",
    borderRadius: 35,
    '&:hover': {
      background: "#048111"
    }
  },
  deleteButton: {
    color: "#fff",
    background: "#F51919",
    boxShadow: "0px 15.8892px 39.723px rgb(191 21 108 / 5%)",
    borderRadius: 35,
    '&:hover': {
      background: "#a80b0b"
    },
    marginLeft: 10
  },
  enrollButton: {
    padding: '7px 22px',
    borderRadius: 5
  },
  cellColumn: { 
    minWidth: 168,
    color: variables.darkThemeText,
    wordBreak: 'break-all'
  }
}));

function RenderButton({item, rowItem, handleItemClick}){
  const classes = useStyles();
  if (item === 'edit') {
    return <IconButton className={classes.editButton} onClick={(evt) => handleItemClick(rowItem, "edit")} ><EditIcon /> </IconButton>
  }else  if(item ===  "enroll") {
    return <Button variant="outlined" className={classes.enrollButton} size="small" color="primary" onClick={(evt) => handleItemClick(rowItem, "edit")} >Enroll</Button>
  }else if(item === "reject"){
    return <IconButton className={classes.deleteButton} onClick={(evt) => handleItemClick(rowItem, "reject")} ><CancelIcon /></IconButton>
  }else if(item === "approve"){
    return <IconButton className={classes.editButton} onClick={(evt) => handleItemClick(rowItem, "approve")} ><CheckCircleIcon /> </IconButton>
  }
  return null;
}

function RenderItem({ rowItem, colItem, handleItemClick }) {
  if (colItem.key === "action" && colItem.actions && colItem.actions.length) {
    return (<>
      {rowItem.status && (rowItem.status === 'declined' || rowItem.status === 'approved') ? rowItem.status : colItem.actions.map(btn => <RenderButton item={btn} key={btn} handleItemClick={handleItemClick} rowItem={rowItem} />)}
    </>);
  }
  if (colItem.key === "logo") {
    return rowItem && rowItem.logo && rowItem.logo !== "" ? <img src={rowItem.logo} style={{ marginLeft: 10 }} alt="logo" width={50} /> : null
  }
  if (colItem.key === "link") {
    return rowItem && rowItem.link && rowItem.link !== "" ? <a href={rowItem.link} target="_blank">{rowItem.link}</a> : null
  }
  return (<>
    {rowItem[colItem.key] ? rowItem[colItem.key] : "N/A"}
  </>);
}

function GenericTable({
  columns,
  list,
  loading,
  handleItemClick,
  left,
  applyMinWidth = true
}) {

  const classes = useStyles();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((item, index) => (
              <TableCell className={(applyMinWidth) ? classes.cellColumn : null}  key={index} style={{ paddingLeft: 10, color: "white", textAlign: left ? "left" : "center", ...((item.key === "action" || item.key === "name" || item.key === "firstName" || item.key === "lastName" || item.key === "userName" || item.key === "location" || item.key === "role")) && { minWidth: 100 } }} >
                {item.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ?
            <TableRow>
              <TableCell style={{ color: variables.darkThemeText }} colSpan={columns.length} align={left ? "left" : "center"} component="td">
                <CircularProgress />
              </TableCell>
            </TableRow>
            :
            list.length > 0 ? list.map((rowItem, rowIndex) => (
              <TableRow key={rowIndex} >
                {columns.map((colItem, colIndex) => {
                  return (
                    <TableCell style={{ color: variables.darkThemeText }} key={colIndex} align={left ? "left" : "center"}>
                      <RenderItem rowItem={rowItem} colItem={colItem} handleItemClick={handleItemClick} />
                    </TableCell>
                  );
                })}
              </TableRow>
            )) :
              <TableRow>
                <TableCell align="center" colSpan={columns.length}>
                  <b>Record not found!</b>
                </TableCell>
              </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default GenericTable;
