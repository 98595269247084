import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Layout from "../../../Components/layouts";
import { ReportServices } from '../../../Services/ReportServices';
import CustomTabs from '../../../Components/CustomTabs';
import {
    RespondentColumn,
    CustomerDimentionDesc,
    StrategyDimentionDesc,
    TechnologyDimentionDesc,
    DataDimentionDesc,
    CultureDimentionDesc,
    OperationDimentionDesc,
    ClosingDesc,
    dataFactor,
    cultureFactor,
    operationFactor,
    technologyFactor,
    strategyFactor,
    customerFactor
} from '../../../utils/constants'
import DetailsAnalyticsTab from '../component/DetailsAnalyticsTab';
import DynamicPage from '../component/DynamicPage'
import ClosingTheGap from '../component/ClosingTheGap';
import Summary from '../component/Summary';
import commonStyles from 'theme/commonStyles';
import { useJsonToCsv } from 'react-json-csv';
import { notify } from 'utils';
import "jspdf-autotable";
import jsPDF from 'jspdf';

const reportServices = new ReportServices();

const DigitalReadliness = () => {

    const commonClasses = commonStyles();
    
    const [selectuserList, setselectUserList] = React.useState();
    const [score, setScore] = React.useState(0);
    const [allwords, setAllwords] = React.useState({});
    const [status, setStatus] = React.useState('Loading...');
    const [userList, setUserList] = React.useState([]);
    

    const [strategyScore, setStrategyScore] = React.useState(0);
    const [strategyAllwords, setStrategyAllwords] = React.useState({});
    const [strategyStatus, setStrategyStatus] = React.useState('Loading...');
    const [strategyUserList, setStrategyUserList] = React.useState([]);

    const [technologyScore, setTechnologyScore] = React.useState(0);
    const [technologyAllwords, setTechnologyAllwords] = React.useState({});
    const [technologyStatus, setTechnologyStatus] = React.useState('Loading...');
    const [technologyUserList, setTechnologyUserList] = React.useState([]);

    const [operationScore, setOperationScore] = React.useState(0);
    const [operationAllwords, setOperationAllwords] = React.useState({});
    const [operationStatus, setOperationStatus] = React.useState('Loading...');
    const [operationUserList, setOperationUserList] = React.useState([]);

    const [cultureScore, setCultureScore] = React.useState(0);
    const [cultureAllwords, setCultureAllwords] = React.useState({});
    const [cultureStatus, setCultureStatus] = React.useState('Loading...');
    const [cultureUserList, setCultureUserList] = React.useState([]);

    const [dataScore, setDataScore] = React.useState(0);
    const [dataAllwords, setDataAllwords] = React.useState({});
    const [dataStatus, setDataStatus] = React.useState('Loading...');
    const [dataUserList, setDataUserList] = React.useState([]);

    const [closingGapAspiration, setClosingGapAspiration] = React.useState({})
    const [closingGapStatusQuo, setClosingGapStatusQuo] = React.useState({})

    const [filterState, setFilterState] = React.useState({
        users: [],
        customer: [],
        pageno: 0,
        perpage: 10,
        project: [],
    });

    const [analyticsFilterState, setAnalyticsFilterState] = React.useState({
        users: [],
        customer: [],
        project: [],
        pageno: 0,
        perpage: 10
    });

    const [customerFilters, setCustomerFilters] = React.useState({
        users: [],
        customer: [],
        project: [],
        dimention: 'customer_dimension'
    });
    const [customerFiltersVal, setCustomerFiltersVal] = React.useState([]);
    const [strategyFilters, setStrategyFilters] = React.useState({
        users: [],
        customer: [],
        project: [],
        dimention: 'strategy_dimension'
    });
    const [strategyFiltersVal, setStrategyFiltersVal] = React.useState([]);
    const [technologyFilters, setTechnologyFilters] = React.useState({
        users: [],
        customer: [],
        project: [],
        dimention: 'technology_dimension'
    });
    const [technologyFiltersVal, setTechnologyFiltersVal] = React.useState([]);
    const [operationFilters, setOperationFilters] = React.useState({
        users: [],
        customer: [],
        project: [],
        dimention:  'operations_dimension'
    });
    const [operationFiltersVal, setOperationFiltersVal] = React.useState([]);
    const [cultureFilters, setCultureFilters] = React.useState({
        users: [],
        customer: [],
        project: [],
        dimention: 'culture_dimension'
    });
    const [cultureFiltersVal, setCultureFiltersVal] = React.useState([]);
    const [dataFilters, setDataFilters] = React.useState({
        users: [],
        customer: [],
        project: [],
        dimention: 'data_dimension'
    });
    const [dataFiltersVal, setDataFiltersVal] = React.useState([]);
    const [downloadReportLoading, setDownloadReportLoading] = React.useState({
        analytics: false,
        progress: false,
        overview: false,
        respondant: false
    });
    const [printReportLoading, setPrintReportLoading] = React.useState({
        analytics: false,
        progress: false,
        overview: false,
        respondant: false
    });
    const { saveAsCsv } = useJsonToCsv();

    const [loading, setLoading] = React.useState(false)
    const [projectsReportData, setProjectsReportData] = React.useState(() => []);
    const [filters, setFilters] = React.useState(null);

    const getProjectReportData = async (updatedFilter) => {
        setLoading(true);
        let result = await reportServices.getProjectReportData('main/drareportdata', updatedFilter ? updatedFilter : filterState);
        if (result && result.status) {
            setFilters(result.data.filter);
            setProjectsReportData(result.data.table);
            setLoading(false);
        } else {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        getProjectReportData();
    }, []);

    const getCustomerDimension = async (obj) => {
        obj.dimention = 'customer_dimension';
        let result = await reportServices.getDrarePortaData(`main/drareportdata`, obj);
        if (result && result.status) {
            let data = result.data;
            // setCustomerFilters(data.filter ? data.filter : {});
            setScore(data.count ? data.count : 0);
            setStatus(data ? data.lst_variable : '')
            setAllwords(data.all_words);
        }
    };

    const getUser = async (customer) => {
        let obj = { dimention: 'customer_dimension', users: [], ...(customer && { customer }) };
        let result = await reportServices.getDraUserData(`main/drauserdata`, obj);
        if (result && result.status) {
            let selectData = result.data.users.map(item => {
                return {
                    name: item.user__userName,
                    value: item.user__uuid
                };
            });
            setUserList(selectData);
        }
    };

    const getStrategyDimension = async obj => {
        obj.dimention = 'strategy_dimension';
        let result = await reportServices.getDrarePortaData(`main/drareportdata`, obj);
        if (result && result.status) {
            let data = result.data;
            // setStrategyFilters(data.filter ? data.filter : [])
            setStrategyScore(data.count ? data.count : 0);
            setStrategyStatus(data ? data.lst_variable : '')
            setStrategyAllwords(data.all_words);
        }
    };

    const getStrategyUser = async (customer) => {
        let obj = { dimention: 'strategy_dimension', users: [], ...(customer && { customer }) };
        let result = await reportServices.getDraUserData(`main/drauserdata`, obj);
        if (result && result.status) {
            let selectData = result.data.users.map(item => {
                return {
                    name: item.user__userName,
                    value: item.user__uuid
                };
            });
            setStrategyUserList(selectData);
        }
    };

    const getTechnologyDimension = async obj => {
        obj.dimention = 'technology_dimension';
        let result = await reportServices.getDrarePortaData(`main/drareportdata`, obj);
        if (result && result.status) {
            let data = result.data;
            // setTechnologyFilters(data.filter ? data.filter : [])
            setTechnologyScore(data.count ? data.count : 0);
            setTechnologyStatus(data ? data.lst_variable : '')
            setTechnologyAllwords(data.all_words);
        }
    }

    const getTechnologyUser = async (customer) => {
        let obj = { dimention: 'technology_dimension', users: [], ...(customer && { customer }) };
        let result = await reportServices.getDraUserData(`main/drauserdata`, obj);
        if (result && result.status) {
            let selectData = result.data.users.map(item => {
                return {
                    name: item.user__userName,
                    value: item.user__uuid
                };
            });
            setTechnologyUserList(selectData);
        }
    }
    
    const getOperationDimension = async obj => {
        obj.dimention = 'operations_dimension';
        let result = await reportServices.getDrarePortaData(`main/drareportdata`, obj);
        if (result && result.status) {
            let data = result.data;
            // setOperationFilters(data.filter ? data.filter : [])
            setOperationScore(data.count ? data.count : 0);
            setOperationStatus(data ? data.lst_variable : '')
            setOperationAllwords(data.all_words);
        }
    }

    const getOperationUser = async (customer) => {
        let obj = { dimention: 'operations_dimension', users: [], ...(customer && { customer }) };
        let result = await reportServices.getDraUserData(`main/drauserdata`, obj);
        if (result && result.status) {
            let selectData = result.data.users.map(item => {
                return {
                    name: item.user__userName,
                    value: item.user__uuid
                };
            });
            setOperationUserList(selectData);
        }
    }

    const getCultureDimension = async obj => {
        obj.dimention = 'culture_dimension';
        let result = await reportServices.getDrarePortaData(`main/drareportdata`, obj);
        if (result && result.status) {
            let data = result.data;
            // setCultureFilters(data.filter ? data.filter : [])
            setCultureScore(data.count ? data.count : 0);
            setCultureStatus(data ? data.lst_variable : '')
            setCultureAllwords(data.all_words);
        }
    }

    const getCultureUser = async (customer) => {
        let obj = { dimention: 'culture_dimension', users: [], ...(customer && { customer }) };
        let result = await reportServices.getDraUserData(`main/drauserdata`, obj);
        if (result && result.status) {
            let selectData = result.data.users.map(item => {
                return {
                    name: item.user__userName,
                    value: item.user__uuid
                };
            });
            setCultureUserList(selectData);
        }
    }

    const getDataDimension = async obj => {
        obj.dimention = 'data_dimension';
        let result = await reportServices.getDrarePortaData(`main/drareportdata`, obj);
        if (result && result.status) {
            let data = result.data;
            // setDataFilters(data.filter ? data.filter : [])
            setDataScore(data.count ? data.count : 0);
            setDataStatus(data ? data.lst_variable : '')
            setDataAllwords(data.all_words);
        }
    }

    const getDataUser = async (customer) => {
        let obj = { dimention: 'data_dimension', users: [], ...(customer && { customer }) };
        let result = await reportServices.getDraUserData(`main/drauserdata`, obj);
        if (result && result.status) {
            let selectData = result.data.users.map(item => {
                return {
                    name: item.user__userName,
                    value: item.user__uuid
                };
            });
            setDataUserList(selectData);
        }
    }

    const getClosingTheGap = async obj => {
        obj.close_gap = true;
        let result = await reportServices.getDrarePortaData(`main/drareportdata`, obj);
        if (result && result.status) {
            let data = result.data;
            setClosingGapAspiration(data.aspiration ? data.aspiration : {});
            setClosingGapStatusQuo(data.status_quo ? data.status_quo : {})
        }
    }

    const closingGapData = [
        {
            name: 'Customer',
            aspiration: closingGapAspiration?.customer,
            sq: closingGapStatusQuo?.customer,
        },
        {
            name: 'Operations',
            aspiration: closingGapAspiration?.operations,
            sq: closingGapStatusQuo?.operations,
        },
        {
            name: 'Strategy',
            aspiration: closingGapAspiration?.strategy,
            sq: closingGapStatusQuo?.strategy,
        },
        {
            name: 'Culture',
            aspiration: closingGapAspiration?.culture,
            sq: closingGapStatusQuo?.culture,
        },
        {
            name: 'Data',
            aspiration: closingGapAspiration?.data,
            sq: closingGapStatusQuo?.data,
        },
        {
            name: 'Technology',
            aspiration: closingGapAspiration?.technology,
            sq: closingGapStatusQuo?.technology,
        }
    ]

    React.useEffect(() => {
        let filterData = {};
        getCustomerDimension(filterData);
        getStrategyDimension(filterData);
        getTechnologyDimension(filterData)
        getOperationDimension(filterData)
        getCultureDimension(filterData)
        getDataDimension(filterData)
        getClosingTheGap(filterData)
        getDataUser()
        getCultureUser()
        getOperationUser()
        getTechnologyUser()
        getUser();
        getStrategyUser();
    }, []);

    React.useEffect(() => {
        if (selectuserList !== undefined) {
            let filterData = JSON.parse(localStorage.getItem('saveFilterData')) ?? {};
            filterData.users = selectuserList;
            getCustomerDimension(filterData);
        }
    }, [selectuserList]);

    const handleItemClick = () => { }

    const handlePageChange = (evt, page) => {
        setAnalyticsFilterState({ ...analyticsFilterState, pageno: page });
        getProjectReportData({ ...analyticsFilterState, pageno: page });
    };

    const handleChange = (val, type, filterType) => {
        if (filterType === 'respondant') {
            console.log("type: ", type);
            setAnalyticsFilterState({ ...analyticsFilterState, [type]: val });
            getProjectReportData({ ...analyticsFilterState, [type]: val, pageno: 0 });
            if(type === 'customer'){
                handleRecallDimationAPI(val);
            }
        }
    }

    const handleRecallDimationAPI = (val) => {
        if( val !== null ){
            setCustomerFilters({...customerFilters, customer: val});
            setStrategyFilters({...strategyFilters, customer: val});
            setTechnologyFilters({...technologyFilters, customer: val});
            setOperationFilters({...operationFilters, customer: val});
            setCultureFilters({...cultureFilters, customer: val});
            setDataFilters({...dataFilters, customer: val});
        }
        let customer = (val) ? val : analyticsFilterState.customer;
        getCustomerDimension({...customerFilters, ...(customerFiltersVal && {users: customerFiltersVal.map(itm => itm.value)}), customer });
        getStrategyDimension({...strategyFilters, ...(strategyFiltersVal && {users: strategyFiltersVal.map(itm => itm.value)}), customer });
        getTechnologyDimension({...technologyFilters , ...(technologyFiltersVal && {users: technologyFiltersVal.map(itm => itm.value)}), customer});
        getCultureDimension({...cultureFilters, ...(cultureFiltersVal && {users: cultureFiltersVal.map(itm => itm.value)}), customer });
        getDataDimension({...dataFilters, ...(dataFiltersVal && {users: dataFiltersVal.map(itm => itm.value)}), customer });
        getOperationDimension({...operationFilters, ...(operationFiltersVal && {users: operationFiltersVal.map(itm => itm.value)}), customer });
        getDataUser(customer)
        getCultureUser(customer)
        getOperationUser(customer)
        getTechnologyUser(customer)
        getUser(customer);
        getStrategyUser(customer);
        getClosingTheGap({ customer });
    }

    const handleSubmitFilter = (type) => {
        if (type === 'respondant') {
            getProjectReportData({...analyticsFilterState, pageno: 0})
            handleRecallDimationAPI(null);
        }
    }

    const handleUserChange = (val, type, filterType) => {
        // let csUsers = [];
        // csUsers = val && val.map(item => item.value);
        if (filterType === 'customerDimension') {
            setCustomerFiltersVal(val);
        }
        if (filterType === 'strategyDimension') {
            setStrategyFiltersVal(val);
        }
        if (filterType === 'technologyDimension') {
            setTechnologyFiltersVal(val);
        }
        if (filterType === 'operationDimension') {
            setOperationFiltersVal(val);
        }
        if (filterType === 'cultureDimension') {
            setCultureFiltersVal(val);
        }
        if (filterType === 'dataDimension') {
            setDataFiltersVal(val);
            // setDataFilters({ ...dataFilters, users: csUsers });
        }
    }

    const handleUserSubmitFilter = (type) => {
        if (type === 'customerDimension') getCustomerDimension({...customerFilters, users: customerFiltersVal.map(itm => itm.value)});
        if (type === 'strategyDimension') getStrategyDimension({...strategyFilters, users: strategyFiltersVal.map(itm => itm.value)});
        if (type === 'technologyDimension') getTechnologyDimension({...technologyFilters , users: technologyFiltersVal.map(itm => itm.value)});
        if (type === 'operationDimension') getOperationDimension({...operationFilters , users: operationFiltersVal.map(itm => itm.value)});
        if (type === 'cultureDimension') getCultureDimension({...cultureFilters, users: cultureFiltersVal.map(itm => itm.value)});
        if (type === 'dataDimension') getDataDimension({...dataFilters, users: dataFiltersVal.map(itm => itm.value)});
    }

    const handleDownload = async (type) => {
        let fields = {}; 
        let columns = RespondentColumn;
        let reportType = 'drareportdata';
        const filterData = {
            pageno: 0,
            perpage: 999999,
            users: [],
            customer: [],
            project: [],
        };
        let reportFilter = {...analyticsFilterState};
        columns.map((itm) => {
            fields[itm.key] = itm.label;
        });
        setDownloadReportLoading({...downloadReportLoading, [type]: true});
        const filename = reportType;
        filterData.certificate_status = reportFilter.certificate_status;
        filterData.customer = reportFilter.customer;
        filterData.course = reportFilter.course;
        filterData.project = reportFilter.project;
        filterData.email = reportFilter.email;
        let result = await reportServices.getProjectReportData(`main/${reportType}`, filterData);
        if (result && result.status) {
            if(result?.data && result?.data?.table){
                let data = result.data.table;
                saveAsCsv({ data, fields, filename });
            }else{
                notify('error', "Report data not found!")
            }
        }
        setDownloadReportLoading({...downloadReportLoading, [type]: false});
    }

    const handlePrint = async (type) => {
        let reportType = 'drareportdata';
        const filterData = {
            pageno: 0,
            perpage: 999999,
            users: [],
            customer: [],
            project: [],
        };
        let reportFilter = {...analyticsFilterState};
        setPrintReportLoading({...printReportLoading, [type]: true});
        const filename = reportType;
        filterData.certificate_status = reportFilter.certificate_status;
        filterData.customer = reportFilter.customer;
        filterData.course = reportFilter.course;
        filterData.project = reportFilter.project;
        filterData.email = reportFilter.email;
        let result = await reportServices.getProjectReportData(`main/${reportType}`, filterData);
        if (result && result.status) {
            if(result?.data && result?.data?.table){
                let columns = [
                    "Customer",
                    "Project",
                    "Username",
                    "Customer Dimension",
                    "Strategy Dimension",
                    "Technology Dimension",
                    "Operations Dimension",
                    "Culture Dimension",
                    "Data Dimension",
                ];
                const pdf = new jsPDF("p", "pt", "a3");
                const rows = [];
                let list = result?.data?.table || [];
                for (let i = 0; i < list.length; i++) {
                    let temp = [];
                    temp = [
                        list[i].customer_name || 'N/A',
                        list[i].project_name || 'N/A',
                        list[i].email || 'N/A',
                        list[i].customer_dim || 'N/A',
                        list[i].strategy_dim || 'N/A',
                        list[i].technology_dim || 'N/A',
                        list[i].operations_dim || 'N/A',
                        list[i].culture_dim || 'N/A',
                        list[i].data_dim || 'N/A',
                    ];
                    rows.push(temp);
                }
                pdf.autoTable(columns, rows, {
                    startY: 65,
                    theme: "grid",
                    styles: {
                    font: "times",
                        halign: "center",
                        cellPadding: 2,
                        lineWidth: 0.5,
                        lineColor: [0, 0, 0],
                        textColor: [0, 0, 0]
                    },
                    headStyles: {
                        textColor: [0, 0, 0],
                        fontStyle: "normal",
                        lineWidth: 0.5,
                        lineColor: [0, 0, 0],
                        fillColor: [166, 204, 247]
                    },
                    alternateRowStyles: {
                        fillColor: [212, 212, 212],
                        textColor: [0, 0, 0],
                        lineWidth: 0.5,
                        lineColor: [0, 0, 0]
                    },
                    rowStyles: {
                        lineWidth: 0.5,
                        lineColor: [0, 0, 0]
                    },
                    tableLineColor: [0, 0, 0]
                });
                pdf.save(filename);
            }else{
                notify('error', "Report data not found!")
            }
        }
        setPrintReportLoading({...printReportLoading, [type]: false});
    }

    return (
        <Layout>
            <main>
                <div className={commonClasses.contentWrapper}>
                    <CustomTabs
                        tabs={[
                            {
                                label: 'Respondents',
                                children: (
                                    <DetailsAnalyticsTab
                                        type='respondant'
                                        title="Digital Readiness Assessment"
                                        filters={filters}
                                        pageName='readline'
                                        allFilterState={analyticsFilterState}
                                        handleChange={handleChange}
                                        columns={RespondentColumn}
                                        loading={loading}
                                        handleItemClick={handleItemClick}
                                        list={projectsReportData}
                                        label="Respondents"
                                        handlePageChange={handlePageChange}
                                        handleSubmitFilter={handleSubmitFilter}
                                        downloadReportLoading={downloadReportLoading}
                                        printReportLoading={printReportLoading}
                                        handlePrint={() => handlePrint('respondant')}
                                        handleDownload={() => handleDownload('respondant')}
                                    />
                                )
                            },
                            {
                                label: 'Customer Dimension',
                                children: (
                                    <DynamicPage
                                        type='customerDimension'
                                        filters={userList}
                                        handleChange={handleUserChange}
                                        handleSubmitFilter={handleUserSubmitFilter}
                                        allFilterState={customerFiltersVal}
                                        pageName="userFilter"
                                        title="Customer Dimension"
                                        description={CustomerDimentionDesc}
                                        score={score}
                                        allwords={allwords}
                                        heading={status}
                                    />
                                )
                            },
                            {
                                label: 'Strategy Dimension',
                                children: (
                                    <DynamicPage
                                        type='strategyDimension'
                                        filters={strategyUserList}
                                        handleChange={handleUserChange}
                                        handleSubmitFilter={handleUserSubmitFilter}
                                        allFilterState={strategyFiltersVal}
                                        pageName="userFilter"
                                        title="Strategy Dimension"
                                        description={StrategyDimentionDesc}
                                        score={strategyScore}
                                        allwords={strategyAllwords}
                                        heading={strategyStatus}
                                    />
                                )
                            },
                            {
                                label: 'Technology Dimension',
                                children: (
                                    <DynamicPage
                                        type='technologyDimension'
                                        filters={technologyUserList}
                                        handleChange={handleUserChange}
                                        handleSubmitFilter={handleUserSubmitFilter}
                                        allFilterState={technologyFiltersVal}
                                        pageName="userFilter"
                                        title="Technology Dimension"
                                        description={TechnologyDimentionDesc}
                                        score={technologyScore}
                                        allwords={technologyAllwords}
                                        heading={technologyStatus}
                                    />
                                )
                            },
                            {
                                label: 'Operations Dimension',
                                children: (
                                    <DynamicPage
                                        type='operationDimension'
                                        filters={operationUserList}
                                        handleChange={handleUserChange}
                                        handleSubmitFilter={handleUserSubmitFilter}
                                        allFilterState={operationFiltersVal}
                                        pageName="userFilter"
                                        title="Operations Dimension"
                                        description={OperationDimentionDesc}
                                        score={operationScore}
                                        allwords={operationAllwords}
                                        heading={operationStatus}
                                    />
                                )
                            },
                            {
                                label: 'Culture Dimension',
                                children: (
                                    <DynamicPage
                                        type='cultureDimension'
                                        filters={cultureUserList}
                                        handleChange={handleUserChange}
                                        handleSubmitFilter={handleUserSubmitFilter}
                                        allFilterState={cultureFiltersVal}
                                        pageName="userFilter"
                                        title="Culture Dimension"
                                        description={CultureDimentionDesc}
                                        score={cultureScore}
                                        allwords={cultureAllwords}
                                        heading={cultureStatus}
                                    />
                                )
                            },
                            {
                                label: 'Data Dimension',
                                children: (
                                    <DynamicPage
                                        type='dataDimension'
                                        filters={dataUserList}
                                        handleChange={handleUserChange}
                                        handleSubmitFilter={handleUserSubmitFilter}
                                        allFilterState={dataFiltersVal}
                                        pageName="userFilter"
                                        title="Data Dimension"
                                        description={DataDimentionDesc}
                                        score={dataScore}
                                        allwords={dataAllwords}
                                        heading={dataStatus}
                                    />
                                )
                            },
                            {
                                label: 'Closing The Gap',
                                children: (
                                    <ClosingTheGap
                                        title='Closing The Gap'
                                        description={ClosingDesc}
                                        data={closingGapData}
                                    />
                                )
                            },
                            {
                                label: 'Summary',
                                children: (
                                    <div style={{ padding: '0 25px' }}>
                                        <Grid container spacing={6}>
                                            <Summary
                                                title="Customer Dimension"
                                                description={CustomerDimentionDesc}
                                                score={score}
                                                heading={status}
                                                list={customerFactor}
                                            />
                                            <Summary
                                                title="Strategy Dimension"
                                                description={StrategyDimentionDesc}
                                                score={strategyScore}
                                                heading={strategyStatus}
                                                list={strategyFactor}
                                            />
                                        </Grid>
                                        <Grid container spacing={6}>
                                            <Summary
                                                title="Technology Dimension"
                                                description={TechnologyDimentionDesc}
                                                score={technologyScore}
                                                heading={technologyStatus}
                                                list={technologyFactor}
                                            />
                                            <Summary
                                                title="Operations Dimension"
                                                description={OperationDimentionDesc}
                                                score={operationScore}
                                                heading={operationStatus}
                                                list={operationFactor}
                                            />
                                        </Grid>
                                        <Grid container spacing={6}>
                                            <Summary
                                                title="Culture Dimension"
                                                description={CultureDimentionDesc}
                                                score={cultureScore}
                                                heading={cultureStatus}
                                                list={cultureFactor}
                                            />
                                            <Summary
                                                title="Data Dimension"
                                                description={DataDimentionDesc}
                                                score={dataScore}
                                                heading={dataStatus}
                                                list={dataFactor}
                                            />
                                        </Grid>
                                        <ClosingTheGap
                                            title='Closing The Gap'
                                            description={ClosingDesc}
                                            data={closingGapData}
                                        />
                                    </div>
                                )
                            }
                        ]}
                    />
                </div>
            </main>
        </Layout>
    );
}

export default DigitalReadliness;