import React, { useState } from 'react';
import { Grid, Typography, IconButton, Input, Button, CircularProgress, Chip, TextField } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useStyle as onboardingStyle } from '../..';
import { CustomerServices } from '../../../../Services/CustomerServices';
import { ProjectServices } from '../../../../Services/ProjectServices';
import { notify } from 'utils';
import { useParams } from 'react-router';
import variables from '../../../../Sys/variable.scss';
import { Autocomplete } from '@material-ui/lab';
import Assesments from 'Pages/MyAssesssments';

const customerServices = new CustomerServices();
const projectServices = new ProjectServices();

const useStyles = makeStyles({
    root: {
        border: '1px solid #D6D6D6',
        borderRadius: 5,
        minHeight: 240,
        backgroundColor: variables.headerColor
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 15,
        fontWeight: 700,
        lineHeight: "18px",
        color: variables.darkThemeText
    },
    pos: {
        marginBottom: 12,
    },
    buttonWrap: {
        width: '100%',
        textAlign: 'center',
        margin: '10px auto'
    },
    removeBtn: {
        background: '#E23A3A',
        color: '#fff',
        height: 32,
        width: 32,
        borderRadius: '50%',
        '&:hover': {
            background: '#c20b0b',
            color: '#fff'
        }
    },
    noHeading: {
        color: variables.darkThemeText,
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '12px',
        marginTop: 15
    },
    noOfItemWrap: {
        display: "flex",
        width: "100%",
        marginTop: 20
    },
    inputStyle: {
        minWidth: 130,
        width: 130,
        padding: 12,
        marginLeft: 6
    }
});

export const AddMoreButton = ({ handleAddNew, action }) => {
    return (
        <IconButton onClick={(evt) => {
            handleAddNew(action);
        }}
            style={{ borderRadius: '50%' }}
            aria-label={`add to more ${action}`}>
            <AddCircleIcon style={{ color: "#07AD17", fontSize: '2.2rem' }} />
        </IconButton>
    )
}

export const RemoveButton = ({ handleClick, action, index }) => {
    const classes = useStyles();
    return (
        <IconButton
            onClick={(evt) => handleClick(action, index)}
            className={classes.removeBtn}
            aria-label={`remove ${action}`}
        >
            <HighlightOffIcon style={{ fontSize: '2.2rem' }} />
        </IconButton>
    )
}

export const NumberInput = ({ handleChange, value, index, action, maxNum, name, disabled }) => {
    const classes = useStyles();
    let inpuProps = { disableUnderline: true};
    if(maxNum !== null){
        inpuProps.max = maxNum;
    }
    return (
        <div className={classes.noOfItemWrap}>
            <Typography className={classes.noHeading} >Number of licenses</Typography>
            <Input
                disableUnderline
                value={value}
                type="number"
                disabled={disabled || false}
                inputProps={{...inpuProps}}
                className={classes.inputStyle}
                placeholder={"Number"}
                name={name || "total_count"}
                onChange={evt => handleChange(evt, index, action)}
            />
        </div>
    );
}

export const initAssignLicenseFields = {
    assessments: [{ assessment_id: '', count: 0, total_count: 0 }],
    courses: [{ course_id: '', count: 0, total_count: 0 }],
    apprenticeships: [{ apprenticeship_id: '', count: 0, total_count: 0 }],
    labs: [{ lab_id: '', count: 0, name: '', total_count: 0, course_id: '' }]
};

const initUserFields = {
    status: 'Open',
    project_name: '',
    company_id: '',
    company_admin_id: '',
    project_admin: '',
    description: ''
};

const AssignLicence = ({
    activeStep,
    handleBack,
    handleNext,
    apprenticeshipList,
    coursesList,
    assessmentList
}) => {

    const classes = useStyles();
    const onBorningClasses = onboardingStyle();
    const { id } = useParams();

    const [assignLicenseForm, setAssignLicenseForm] = useState({ ...initAssignLicenseFields });
    const [submitLoading, setSubmitLoading] = useState(false);
    const [user, setUser] = useState({ ...initUserFields });
    const [removeCourses, setRemoveCourses] = useState([]);
    const [removeApprenticeships, setRemoveApprenticeships] = useState([]);
    const [removeAssessments, setRemoveAssessments] = useState([]);
    const [existingLicence, setExistingLicence] = useState({courses: [], assessments: [], apprenticeships: []});

    React.useEffect(() => {
        getProjectDetails();
    }, []);

    async function getProjectDetails() {
        const projectDetails = await projectServices.getProjectDetails(id || localStorage.getItem('cstmsrSaveFlow'));
        if (projectDetails?.data) {
            const reponseData = projectDetails?.data;
            const { assessment, apprenticeship, course, lab } = reponseData;
            let courseIds = [];
            let assesmentIds = [];
            let apprenticeshipIds = [];
            const courseArray = (course && course.length > 0)
                ? course
                : assignLicenseForm.courses;
            const courseResult = [];
            courseArray.map(item => {
                courseResult.push({
                    course_id: {id: item.uuid, name: (item.provider === 'Xpert Skills') ? `${item.name} - ${item.provider}` : item.name },
                    count: item.count ? parseInt(item.count) : 0,
                    ...item
                });
                courseIds.push(item.uuid);
            });
            let assessments = (assessment && assessment?.length) ? 
            assessment.map(itm => {
                assesmentIds.push(itm.uuid);
                return ({ 
                    assessment_id: itm, 
                    count: parseInt(itm.count),
                    ...itm 
                });
            }) : assignLicenseForm.assessments;
            let apprenticeships = (apprenticeship && apprenticeship?.length) ? 
            apprenticeship.map(itm => {
                apprenticeshipIds.push(itm.uuid);
                return ({ 
                    apprenticeship_id: itm, 
                    count: parseInt(itm.count),
                    ...itm 
                });
            }) : assignLicenseForm.apprenticeships;
            const xpertSkillsLabs = await projectServices.getLabsByCourse({ course_ids: courseIds });
            let labs = (lab && lab?.length) ? 
            lab.map(itm => {
                const selectedLab = (xpertSkillsLabs?.data) && xpertSkillsLabs.data.find(lb => lb?.uuid === itm?.uuid);
                return { 
                    lab_id: itm,
                    course_id: selectedLab?.course_id || '',
                    count: parseInt(itm.count),
                    ...itm 
                }
            }) : assignLicenseForm.labs;
            setUser({
                project_name: reponseData.project_name,
                status: reponseData?.status || "Open",
                description: reponseData.description,
                company_id: reponseData.company_id,
                company_admin_id: reponseData.company_admin_id,
                project_admin: reponseData.project_admin
            });
            if(!lab.length){
                if(xpertSkillsLabs?.data){
                    const selectedLabs = (xpertSkillsLabs?.data.length) ? xpertSkillsLabs?.data.map(itm => {
                        let selectedCourse = courseResult.find(crs => crs?.course_id?.id === itm?.course_id);
                        return {
                            lab_id: { id: itm.uuid, name: itm.name },
                            name: itm.name,
                            count: selectedCourse?.count || 0,
                            course_id: itm?.course_id || '',
                            total_count: selectedCourse?.total_count || 0
                        };
                    }) : [...initAssignLicenseFields.labs]; 
                    labs = selectedLabs;
                }
            }
            setExistingLicence({ courses: courseIds, assessments: assesmentIds, apprenticeships: apprenticeshipIds });
            setAssignLicenseForm({ labs: labs ,courses: courseResult, assessments: assessments, apprenticeships: apprenticeships });
            
        }
    }

    const handleChangeInput = (e, idx, type) => {
        const { name, value } = e.target;
        let newVal = value;
        if(name === 'total_count'){
            newVal = parseInt(value);
        }
        setAssignLicenseForm((prevState) => {
            let newState = {...prevState};
            let prvData = [...newState[type]];
            prvData[idx] = { ...prvData[idx], [name]: newVal };
            newState[type] = prvData;
            return newState;
        });
    };
    
    const handleChangeAutoComplete = (value, idx, type, name) => {
        const newVal = value;
        setAssignLicenseForm((prevState) => {
            let newState = {...prevState};
            let prvData = [...newState[type]];
            prvData[idx] = { ...prvData[idx], [name]: newVal };
            newState[type] = prvData;
            return newState;
        });
    };

    const handleRemoveAssignLicence = (type, idx) => {
        let checkExist = assignLicenseForm?.[type]?.[idx];
        let checkKey = (type === 'courses') ? 'course_id' : (type === 'apprenticeships') ? 'apprenticeship_id' : (type === 'assessments') ? 'assessment_id' : '';
        console.log("checkExist: ", checkExist[checkKey]);
        if(checkKey && checkExist[checkKey] && existingLicence[type].includes(checkExist[checkKey]?.id || checkExist[checkKey]?.uuid)){
            if(type === 'courses'){
                setRemoveCourses([...removeCourses, checkExist?.course_id?.id]);
            }else if(type === 'apprenticeships'){
                setRemoveApprenticeships([...removeApprenticeships, checkExist?.apprenticeship_id?.uuid]);
            }else if(type === 'assessments'){
                setRemoveAssessments([...removeAssessments, checkExist?.assessment_id?.uuid]);
            }
        }
        if(type === 'courses'){
            autoSelectLabsByCourse(null, idx, 'remove');
        }else{
            setAssignLicenseForm((prevState) => {
                let newState = { ...prevState };
                let prvData = newState[type];
                prvData.splice(idx, 1);
                newState[type] = (prvData?.length) ? prvData : [...initAssignLicenseFields[type]];
                return newState;
            });
        }
    }

    const handleAddAssignLicence = (type) => {
        setAssignLicenseForm((prevState) => {
            let newState = { ...prevState };
            let prvData = [...newState[type]];
            prvData.push({ ...initAssignLicenseFields[type][0] });
            newState[type] = prvData;
            return newState;
        });
    }

    const autoSelectLabsByCourse = async (course, indx, action = 'add') => {
        let removeCourse = (course === null && assignLicenseForm.courses.length) ? {...assignLicenseForm.courses[indx]} : null;
        const courseIds = (removeCourse && removeCourse?.course_id) ? [removeCourse?.course_id?.id] : (course?.id) ? [course?.id] : [];
        let selectedLabs = (assignLicenseForm.labs.length && assignLicenseForm.labs[0].lab_id) ? [...assignLicenseForm.labs] : [];
        if((course && course?.name?.includes('Xpert Skills')) || (removeCourse && removeCourse?.course_id && removeCourse.course_id?.name?.includes('Xpert Skills'))){
            if(action === 'add'){
                const courseLabs = await projectServices.getLabsByCourse({ course_ids: courseIds });
                if(courseLabs?.data){
                    courseLabs?.data.forEach(itm => {
                        let selectedCourse = {...course};
                        selectedLabs.push({
                            lab_id: { id: itm.uuid, name: itm.name },
                            name: itm.name,
                            count: selectedCourse?.count || 0,
                            total_count: selectedCourse?.total_count || 0,
                            course_id: itm?.course_id || ''
                        });
                    });
                }
            }else{
                if(removeCourse && removeCourse.course_id){
                    let removeLabsIndex = []; 
                    assignLicenseForm.labs.map((lab, idx) => {
                        if(lab.course_id === removeCourse.course_id?.id){
                            removeLabsIndex.push(idx);
                        }
                    });
                    const indexSet = new Set(removeLabsIndex);
                    selectedLabs = selectedLabs.filter((value, i) => !indexSet.has(i));
                }
            }
        }
        setAssignLicenseForm((prevState) => {
            let newState = {...prevState};
            let prvData = [...newState.courses];
            if(action === 'add'){
                prvData[indx] = { ...prvData[indx], course_id: course };
            }else{
                prvData.splice(indx, 1);
            }
            newState.labs = (selectedLabs.length) ? [...selectedLabs] : [...initAssignLicenseFields.labs];
            newState.courses = (prvData.length) ? prvData : [...initAssignLicenseFields.courses];
            return newState;
        });
    }

    const getAssessments = () => {
        return (
            assignLicenseForm.assessments.map((item, key) => (
                <div key={`assisment-${key}`} className={classes.itemContain}>
                    <Card className={classes.root} style={key > 0 ? { marginTop: 30 } : null}>
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Assessments Allocated : {item.count} / {item.total_count}
                            </Typography>
                            <Autocomplete
                                id="tags-outlined"
                                value={item.assessment_id}
                                name="assessment_id"
                                options={assessmentList}
                                getOptionLabel={option => option.name}
                                onChange={(event, newValue) => {
                                    let check = assignLicenseForm.assessments.find(cr => (cr?.assessment_id?.uuid === newValue?.uuid));
                                    if(check){
                                        notify('error', `This assessment is already selected!`);
                                        return null;
                                    }
                                    handleChangeAutoComplete(newValue, key, 'assessments', 'assessment_id');
                                }}
                                style={{ marginTop: 36, marginBottom: 45 }}
                                limitTags={2}
                                renderTags={(value, getTagProps) => value.map((option, index) => (
                                    <Chip variant="outlined" size="small" label={option.name} {...getTagProps({ index })} />
                                ))}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder="Select Assessments"
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            style:{ maxWidth: 226, minWidth: 228, padding: "4px 10px" }
                                        }}
                                    />
                                )}
                            />
                            <NumberInput
                                value={item.total_count ? parseInt(item.total_count) : 0}
                                index={key}
                                maxNum={null}
                                action="assessments"
                                handleChange={handleChangeInput}
                            />
                        </CardContent>
                    </Card>
                    <div className={classes.buttonWrap}>
                        {key === (assignLicenseForm.assessments.length -1) && <AddMoreButton action={'assessments'} handleAddNew={handleAddAssignLicence} />}
                        {<RemoveButton action={'assessments'} index={key} handleClick={handleRemoveAssignLicence} />}
                    </div>
                </div>
            ))
        )
    }

    const getCourses = () => {
        return (
            assignLicenseForm.courses.map((item, key) => (
                <div key={`course-${key}`} className={classes.itemContain}>
                    <Card className={classes.root} style={key > 0 ? { marginTop: 30 } : null}>
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Courses Allocated : {item.count} / {item.total_count}
                            </Typography>
                            <Autocomplete
                                id="tags-outlined"
                                value={item.course_id}
                                name="course_id"
                                options={coursesList}
                                getOptionLabel={option => option.name}
                                onChange={(event, newValue) => {
                                    let check = assignLicenseForm.courses.find(cr => cr?.course_id?.id === newValue?.id);
                                    if(check){
                                        notify('error', `This course is already selected!`);
                                        return null;
                                    }
                                    return autoSelectLabsByCourse(newValue, key, 'add');
                                }}
                                style={{ marginTop: 36, marginBottom: 45 }}
                                limitTags={2}
                                renderTags={(value, getTagProps) => value.map((option, index) => (
                                    <Chip variant="outlined" size="small" label={option.name} {...getTagProps({ index })} />
                                ))}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder="Select Courses"
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            style:{ maxWidth: 226, minWidth: 228, padding: "4px 10px" }
                                        }}
                                    />
                                )}
                            />
                            <NumberInput
                                value={item.total_count ? parseInt(item.total_count) : 0}
                                index={key}
                                maxNum={null}
                                action="courses"
                                handleChange={handleChangeInput}
                            />
                        </CardContent>
                    </Card>
                    <div className={classes.buttonWrap}>
                        {key === (assignLicenseForm.courses.length -1) && <AddMoreButton action={'courses'} handleAddNew={handleAddAssignLicence} />}
                        {<RemoveButton index={key} action={'courses'} handleClick={handleRemoveAssignLicence} />}
                    </div>
                </div>
            ))
        )
    }

    const getApprenticeships = () => {
        return (
            assignLicenseForm.apprenticeships.map((item, key) => (
                <div key={`assisment-${key}`} className={classes.itemContain}>
                    <Card className={classes.root} style={key > 0 ? { marginTop: 30 } : null}>
                        <CardContent>
                            {/* {key === 0 && */}
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Apprenticeships Allocated : {item.count} / {item.total_count}
                            </Typography>
                            <Autocomplete
                                id="tags-outlined"
                                value={item.apprenticeship_id}
                                name="apprenticeship_id"
                                options={apprenticeshipList}
                                getOptionLabel={option => option.name}
                                onChange={(event, newValue) => {
                                    let check = assignLicenseForm.apprenticeships.find(cr => (cr?.apprenticeship_id?.uuid === newValue?.uuid));
                                    if(check){
                                        notify('error', `This apprenticeship is already selected!`);
                                        return null;
                                    }
                                    return handleChangeAutoComplete(newValue, key, 'apprenticeships', 'apprenticeship_id');
                                }}
                                style={{ marginTop: 36, marginBottom: 45 }}
                                limitTags={2}
                                renderTags={(value, getTagProps) => value.map((option, index) => (
                                    <Chip variant="outlined" size="small" label={option.name} {...getTagProps({ index })} />
                                ))}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder="Select Apprenticeships"
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            style:{ maxWidth: 226, minWidth: 228, padding: "4px 10px" }
                                        }}
                                    />
                                )}
                            />
                            <NumberInput
                                value={item.total_count ? parseInt(item.total_count) : 0}
                                index={key}
                                maxNum={null}
                                action="apprenticeships"
                                handleChange={handleChangeInput}
                            />
                        </CardContent>
                    </Card>
                    <div className={classes.buttonWrap}>
                        {key === (assignLicenseForm.apprenticeships.length -1) && <AddMoreButton action={'apprenticeships'} handleAddNew={handleAddAssignLicence} />}
                        {<RemoveButton index={key} action={'apprenticeships'} handleClick={handleRemoveAssignLicence} />}
                    </div>
                </div>
            ))
        )
    }
    
    const getLabs = () => {
        return (assignLicenseForm.labs.map((item, key) => (
                <div key={`labs-${key}`} className={classes.itemContain}>
                    <Card className={classes.root} style={key > 0 ? { marginTop: 30 } : null}>
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Labs Allocated : {item.count} / {item?.total_count || 0}
                            </Typography>
                            <Input
                                variant="standard"
                                placeholder="Select Labs"
                                value={item.name}
                                disabled
                                style={{ minWidth: 180, width: '100%', padding: 10, marginTop: 36 }}
                                disableUnderline
                            />
                            <NumberInput
                                value={item.total_count ? parseInt(item.total_count) : 0}
                                index={key}
                                maxNum={null}
                                action="labs"
                                disabled={true}
                            />
                        </CardContent>
                    </Card>
                </div>
            ))
        )
    }

    const formValidationCheck = async (evt) => {
        const {
            assessments,
            courses,
            apprenticeships,
            labs
        } = assignLicenseForm;
        let isValidCourseCount = [];
        let isValidAsseCount = [];
        let isValidApprCount = [];
        const payload = {
            remove_assesments: removeAssessments,
            remove_courses: removeCourses,
            remove_apprenticeships: removeApprenticeships,
            status: user.status,
            customer_id: id || localStorage.getItem('cstmsrSaveFlow'),
            course_customer: (courses.length && courses[0]?.course_id) ? courses.map(itm => {
                if(itm?.course_id?.id && !itm.total_count){
                    isValidCourseCount.push(itm);
                }
                return {...itm, count: itm.total_count, course_id: itm.course_id.id };
            }) : [],
            assessment_customer: (assessments.length && assessments[0]?.assessment_id) ? assessments.map(itm => {
                if(itm?.assessment_id?.uuid && !itm.total_count){
                    isValidAsseCount.push(itm);
                }
                return ({...itm, count: itm.total_count, assessment_id: itm.assessment_id.uuid })
            }) : [],
            apprenticeship_customer: (apprenticeships.length && apprenticeships[0].apprenticeship_id) ? apprenticeships.map(itm => {
                if(!itm.total_count){
                    isValidApprCount.push(itm);
                }
                return ({...itm, count: itm.total_count, apprenticeship_id: itm.apprenticeship_id.uuid });
            }) : [],
            lab_customer: (labs.length && labs[0]?.lab_id) ? labs.map(itm => {
                let course = courses.find(crs => crs?.course_id?.id === itm.course_id);
                return {
                    ...itm,
                    count: course?.total_count || 0,
                    total_count: course?.total_count || 0, 
                    lab_id: itm?.uuid || itm?.lab_id?.id || ''
                };
            }) : []
        };
        if(isValidAsseCount.length){
            let asses = isValidAsseCount.map(cs => cs?.assessment_id?.name || '');
            notify("error", `You can't Assign ${asses.join(", ")} Assessment with 0 Count!`);
            return null;
        }
        if(isValidCourseCount.length){
            let courses = isValidCourseCount.map(cs => cs?.course_id?.name || '');
            notify("error", `You can't Assign ${courses.join(", ")} Course with 0 Count!`);
            return null;
        }
        if(isValidApprCount.length){
            let appris = isValidApprCount.map(cs => cs?.apprenticeship_id?.name || '');
            notify("error", `You can't Assign ${appris.join(", ")} Apprenticeship with 0 Count!`);
            return null;
        }
        try{
            setSubmitLoading(true);
            const result = await customerServices.assignCustomerLicence(payload, id || localStorage.getItem('cstmsrSaveFlow'));
            if (result && result.status) {
                if(result && result.message.includes('associated with user')){
                    setSubmitLoading(false);
                    setRemoveCourses([]);
                    setRemoveAssessments([]);
                    setRemoveApprenticeships([]);
                    notify("error", "Some Courses/Assessments/Apprenticeships are already assigned with projects/users, You can't remove it, Please Unallocated from there and try.");
                    return getProjectDetails();
                }else{
                    localStorage.setItem('assign_lecense', true);
                    localStorage.setItem('refreshlic', true);
                    localStorage.setItem('getAllCustomer', true);
                    handleNext(evt);
                    setSubmitLoading(false);
                    notify("success", result.message);
                    if(id){
                        localStorage.setItem('editstep', 2);
                    }
                }
            } else {
                setSubmitLoading(false);
                notify("error", (result && result.message) ? result.message : 'Something Went Wrong');
            }
        }
        catch(err){
            setSubmitLoading(false);
            console.log("error", err);
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={4}>
                <Grid item xs={3}>
                    {getAssessments()}
                </Grid>
                <Grid item xs={3}>
                    {getCourses()}
                </Grid>
                <Grid item xs={3}>
                    {getApprenticeships()}
                </Grid>
                <Grid item xs={3}>
                    {getLabs()}
                </Grid>
            </Grid>
            <div className={onBorningClasses.buttons}>
                <div className={onBorningClasses.wrapper}>
                    {activeStep !== 0 && (
                        <Button
                            onClick={handleBack}
                            variant="contained"
                            color="primary"
                            className={onBorningClasses.cancelBtn}>
                            Back
                        </Button>
                    )}
                    <Button
                        type={"button"}
                        onClick={formValidationCheck}
                        variant="contained"
                        color="primary"
                        disabled={submitLoading}
                        className={onBorningClasses.button}
                    >
                        {submitLoading ? (
                            <CircularProgress
                                size={18}
                                className={classes.buttonProgress}
                            />
                        ) : 'Next'}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default React.memo(AssignLicence);