import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Layout from "../../Components/layouts";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { Button, CircularProgress, Paper, Typography } from '@material-ui/core';
import { CalendarServices } from '../../Services/CalendarServices';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import FullCalendar from './components/FullCalendar'
import CalendarSidebar from './components/CalendarSidebar';
import EventModal from './components/EventModal'
import { notify } from '../../utils';
import './calender.css';
import SingleEventModal, { calculateDuration } from './components/SingleEventModal';
import commonStyles from 'theme/commonStyles';
import SearchBoxInput from 'Components/SearchBoxInput';
import moment from 'moment';

const calendarServices = new CalendarServices();

function renderEventContent(eventInfo) {
    return (
        <>
            <b>{eventInfo.timeText}</b>
            <i>{eventInfo.event.title}</i>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    flexToppx: {
        marginBottom: 24, 
        borderBottom: '1px solid #d6d6d6', 
        paddingBottom: 24
    },
    btn: {
        color: '#3D7DDD',
        border: '1px solid #3D7DDD',
        padding: '11px 25px',
        borderRadius: 15,
        background: '#fff',
        "&:hover": {
            border: "1px solid #3D7DDD",
            background: "#3D7DDD",
            color: "#fff"
        }
    },
    headerTop: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    paper: {
        padding: theme.spacing(2),
        margin: "20px 0px",
        color: theme.palette.text.secondary,
    },
    eventRoot: {
        flexGrow: 1,
    },
    cal: {
        textAlign: 'center',
        background: '#FFFFFF',
        boxShadow: '0px 4px 30px rgb(1 2 0 / 9%)',
        borderRadius: 10,
        marginTop: 20
    },
    date: {
        padding: '15px 10px',
        color: '#000',
        margin: 0,
        fontSize: 22,
        fontWeight: 900,
    },
    eventDateFilter: {
        border: "1px solid #808080",
        borderRadius: 25,
        padding: '12px 18px'
    }
}));

const Header = ({ selectedDate, handleDateChange, onClickMonth }) => {
    const classes = useStyles();
    
    return (
        <div className={classes.headerTop}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{
                maxWidth: 180,
                border: '2px solid #3d7ddd',
                borderRadius: 9
            }}>
                <DatePicker
                    variant="inline"
                    openTo="month"
                    minDate={new Date()}
                    className={"timePickerStyle"}
                    views={["year", "month"]}
                    value={selectedDate}
                    inputProps={{ style:{ padding: 6 } }}
                    onChange={handleDateChange}
                />
            </MuiPickersUtilsProvider>
            <div>
                <Button className={classes.btn} style={{ marginRight: 12 }} onClick={onClickMonth} variant="outlined">Month</Button>
                    <Button 
                        className={classes.btn} style={{
                            background: "#3D7DDD",
                            color: "#fff",
                            border: "1px solid #3D7DDD"
                        }}
                        variant="outlined">
                        Event
                    </Button>
            </div>
        </div>
    )
}

const SearchAndExport = ({ handleSearch, showCalendar, search }) => {
    const classes = useStyles();
    return (
        <>
            <Grid container justifyContent="space-between" className={classes.flexToppx} >
                <Grid item>
                    {!showCalendar && <SearchBoxInput 
                        placeholder={"Search for training you want"}
                        color={'secondary'}
                        value={search}
                        handleChange={evt => handleSearch(evt)}
                    />}
                </Grid>
                <Grid item>
                    {/* <Button className={classes.btn} variant="outlined">Export Calendar</Button> */}
                </Grid>
            </Grid>
        </>
    )
}

const EventContainer = ({ item }) => {
    const classes = useStyles();
    const ChangeColorFunction = () => {
        var ColorCode = 'rgb(' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ')';
        return ColorCode
    }
    const month = new Date(item.start_date).toLocaleString('default', { month: 'long' })
    const date = new Date(item.start_date).getDate();
    // const duration = calculateDuration(item.start_date + ' ' + item.start_time, item.end_date + ' ' + item.end_time);

    const totalTime = calculateDuration(item.start_date + ' ' + item.start_time, item.end_date + ' ' + item.end_time);
    return (
        <Paper elevation={3} className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item xs={2} className={classes.calendarView}>
                    <div className={classes.cal}>
                        <h6 style={{
                            padding: 10,
                            fontSize: 15,
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                            background: `${ChangeColorFunction()}`,
                            color: 'white',
                            margin: 0,
                        }}>{month}</h6>
                        <h5 className={classes.date}>{date}</h5>
                    </div>
                </Grid>
                <Grid item xs={3} className={classes.intro}>
                    <h3>{item.name}</h3>
                    {/* <div style={{ display: 'flex', margin: 0 }}>
                        <NotificationsActiveIcon className={classes.icon} />
                        <p style={{ margin: 0, fontSize: 13, padding: 5 }}>{duration}</p>
                    </div> */}
                    <div style={{ display: 'flex', margin: 0 }}>
                        <QueryBuilderIcon className={classes.icon} />
                        <p style={{ margin: 0, fontSize: 13, padding: 5 }}>{totalTime}</p>
                    </div>
                </Grid>
                <Grid item xs={4} className={classes.notes}>
                    <h3>Note</h3>
                    <p style={{ fontSize: 12, overflow: 'auto', width: '100%' }}>{item.note}</p>
                </Grid>
                <Grid item xs={2} className={classes.notes}>
                    <h3>URL</h3>
                    {item.url !== "" ? <a href={item.url} style={{ color: "#3D7DDD", textDecoration: 'none' }} target="_blank">Event Link</a> : "Not Available"}
                </Grid>
                {/* <Button style={{
                        color: '#3D7DDD',
                        border: '1px solid #3D7DDD',
                        padding: '5px 10px',
                        borderRadius: 25,
                        background: '#fff',
                        fontSize: 10
                    }}>Export</Button> */}
            </Grid>
        </Paper>
    )
}

function formatDateYYMMDD(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

function pad2(number) {
    return number = (number < 10 ? '0' : '') + number;
}

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

function getDatesFromStartEnd(startDate, stopDate) {
    let dateArray = new Array();
    let currentDate = new Date(startDate);
    while (currentDate.getTime() <= new Date(stopDate).getTime()) {
        dateArray.push(moment(new Date (currentDate)).format("DD-MM-YYYY"));
        currentDate = currentDate.addDays(1);
    }
    return dateArray;
}

function getRendomClass(){
    const items = ['evt-bg-color1', 'evt-bg-color2', 'evt-bg-color3', 'evt-bg-color4', 'evt-bg-color5'];
    return items[items.length * Math.random() | 0];
}

const Calendar = () => {
    const classes = useStyles();
    const commonClasses = commonStyles();
    const [state, setState] = React.useState({
        weekendsVisible: true,
        currentEvents: []
    });
    const [openEventModal, setOpenEventModal] = React.useState(false);
    const [calendarEvents, setCalendarEvents] = React.useState([]);
    const [selectedEventID, setSelectedEventID] = React.useState("");
    const [openSingleEvent, setOpenSingleEvent] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [eventList, setEventList] = React.useState([]);
    const [eventLoding, setEventLoding] = React.useState(false);
    const [sideBarEventList, setSideBarEventList] = React.useState([]);
    const [sideBarDate, setSideBarDate] = React.useState(new Date());
    const [showCalendar, setShowCalendar] = React.useState(true);
    const [currentDateInfo, setCurrentDateInfo] = React.useState(null);
    const [eventFilterDate, setEventFilterDate] = React.useState(new Date());

    React.useEffect(() => {
        setLoading(false);
        const crtMonth = eventFilterDate.getMonth()+1;
        getEventList(`?start_date__month=${pad2(crtMonth)}`);
        const query = `?start_date__gte=${formatDateYYMMDD(sideBarDate)}&end_date__lte=${formatDateYYMMDD(sideBarDate)}`;
        getSideBarEventList(query);
    }, []);

    const getCalanderEvents = async (query) => {
        const events = await calendarServices.getAllEvents(query);
        if (events.results && events.results.length) {
            setEventList(events.results);
            const eventCalendarList = events.results && events.results.length > 0 && events.results.map(item => {
                return {
                    id: item.uuid,
                    title: item.name,
                    start: moment(new Date(item.start_date)).format("YYYY-MM-DD") + 'T' + item.start_time,
                    end: moment(new Date(item.start_date)).format("YYYY-MM-DD") + 'T' + item.end_time,
                    className:[getRendomClass()],
                    description: item.note,
                }
            });
            setCalendarEvents(eventCalendarList);
        }
    }

    const getEventList = async (query) => {
        setEventLoding(true);
        const events = await calendarServices.getAllEvents(query);
        if (events) {
            setEventList(events?.results || []);
        }
        setEventLoding(false);
    }
    
    const getSideBarEventList = async (query) => {
        const events = await calendarServices.getAllEvents(query);
        if (events.results && events.results.length) {
            setSideBarEventList(events.results);
        }
    }

    const handleEventClick = (clickInfo) => {
        console.log("clickInfo.event._def.publicId: ", clickInfo.event._def.publicId);
        setSelectedEventID(clickInfo.event._def.publicId)
        setOpenSingleEvent(true);
    };

    const handleEvents = (events) => {
        setState({
            currentEvents: events
        });
    };

    const handleDateSelect = () => {
        
    };

    const handleSaveEvents = async (data, dateRange) => {
        const startTime = moment(new Date(data.from)).format('HH:mm');
        const endTime =  moment(new Date(data.to)).format('HH:mm');
        let payload = {
            name: data.title,
            start_time: startTime,
            end_time: endTime,
            repeat_mode: data.repeat_mode,
            main_type: data.type,
            event_type: data.eventType,
            url: data.url,
            note: data.note,
            project_id: data.project.id,
            description: data.note
        };
        if(["Weekly", "Monthly"].includes(data.repeat_mode)){
            payload.start_date = getDatesFromStartEnd(dateRange.startDate, dateRange.endDate);
            payload.end_date = moment(new Date(data.expiryDate)).format("DD-MM-YYYY");
            payload.expiry_date = moment(new Date(data.expiryDate)).format("DD-MM-YYYY");
        }else{
            payload.start_date = data.startDate;
            payload.end_date = data.endDate;
            payload.expiry_date = data.endDate;
        }
        if (data.type === "Event") {
            if (data.eventType === "Assessment") {
                payload.assessment_activity = data.courseActivity
                payload.assessment_id = data.course.assessment_id
            } else if (data.eventType === "Apprenticeship") {
                payload.apprenticeship_activity = data.courseActivity
                payload.apprenticeship_id = data.course.apprenticeship_id
            } else {
                payload.course_activity = data.courseActivity;
                payload.course_id = data.course.course_id
            }
        }
        const result = await calendarServices.addEvent(payload);
        if (result && result.status) {
            setOpenEventModal(false)
            notify("success", result.message)
            handleGetEventByMonth(currentDateInfo);
        }
        setLoading(false);
    }

    const handleSaveSingleEvent = () => {

    }

    const onClickMonth = () => {
        setShowCalendar(true);
    }

    const handleClickCalendarEvent = () => {
        setShowCalendar(false)
    }

    const handleSearch = (evt) => {
        setSearch(evt.target.value);
        const crtMonth = eventFilterDate.getMonth()+1;
        getEventList(`?start_date__month=${pad2(crtMonth)}&name__icontains=${evt.target.value}`);
    }

    const handleAddEvent = () => {
        setOpenEventModal(true)
    }

    const handleGetEventByMonth = (dateInfo) => {
        setCurrentDateInfo({start: dateInfo.start, end: dateInfo.end});
        const query = `?limit=10000&start_date__gte=${formatDateYYMMDD(dateInfo.start)}&end_date__lte=${formatDateYYMMDD(dateInfo.end)}`;
        getCalanderEvents(query);
    }

    return (
        <Layout>
            <main>
                <div className={commonClasses.contentWrapper}>
                    <SearchAndExport 
                        search={search} 
                        showCalendar={showCalendar} 
                        handleSearch={handleSearch} 
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={8}>
                            {showCalendar ? (
                                <FullCalendar
                                    handleEventClick={handleEventClick}
                                    handleEvents={handleEvents}
                                    renderEventContent={renderEventContent}
                                    handleDateSelect={handleDateSelect}
                                    initialEvents={calendarEvents}
                                    weekendsVisible={true}
                                    handleClickCalendarEvent={handleClickCalendarEvent}
                                    handleGetEventByMonth={handleGetEventByMonth}
                                />
                            ) : 
                            <>
                                <Header 
                                    handleDateChange={date => {
                                        setEventFilterDate(date);
                                        const crtMonth = new Date(date).getMonth()+1;
                                        return getEventList(`?start_date__month=${pad2(crtMonth)}`);
                                    }}
                                    selectedDate={eventFilterDate}
                                    onClickMonth={onClickMonth} 
                                />
                                <div style={{ marginTop: 20 }}>
                                    {eventLoding ? <div className={classes.eventRoot}>
                                        <Paper elevation={3} className={classes.paper}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} style={{textAlign: "center"}}>
                                                    <CircularProgress color="primary" size={20} />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </div> : eventList && eventList.length > 0 ? eventList.map(item => (
                                        <div className={classes.eventRoot}>
                                            <EventContainer item={item} />
                                        </div>
                                    )) : 
                                    <div className={classes.eventRoot}>
                                        <Paper elevation={3} className={classes.paper}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} className={classes.calendarView}>
                                                    <Typography variant="h4">Events not found for this month!</Typography>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </div>
                                }
                                </div>
                            </>}
                        </Grid>
                        <Grid item xs={4}>
                            <CalendarSidebar 
                                eventList={sideBarEventList} 
                                handleAddEvent={handleAddEvent} 
                                date={sideBarDate}
                                changeDate={(date) => {
                                    const query = `?start_date__gte=${formatDateYYMMDD(new Date(date))}&end_date__lte=${formatDateYYMMDD(new Date(date))}`;
                                    getSideBarEventList(query);
                                    setSideBarDate(date);
                                }} 
                            />
                        </Grid>
                    </Grid>
                </div>
            </main>
            <EventModal
                open={openEventModal}
                handleClose={evt => setOpenEventModal(false)}
                handleSaveEvents={handleSaveEvents}
                btnLoading={loading}
            />
            {selectedEventID && 
            <SingleEventModal
                open={openSingleEvent}
                handleClose={evt => {
                    setOpenSingleEvent(false);
                    setSelectedEventID("");
                }}
                handleSaveEvents={handleSaveSingleEvent}
                id={selectedEventID}
            />}
        </Layout>
    );
}

export default Calendar;